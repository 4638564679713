import { ClassSetEntryAction } from './actions'
import { ClassSetEntryActionType, CurrentClassSetEntryState } from './types'

const initialState: CurrentClassSetEntryState = {
  domainData: {
    classSets: ['', ''],
    metadata: {
      name: '',
      remarks: '',
    },
    classSetId: undefined,
  },
  appState: {
    classSetEntryState: 'ClassSetState',
    classSetEntrySubState: {
      metadataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const ClassSetEntryReducer = (
  state: CurrentClassSetEntryState = initialState,
  action: ClassSetEntryAction
): CurrentClassSetEntryState => {
  switch (action.type) {
    case ClassSetEntryActionType.SET_CLASS_SET_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: action.payload.classSet,
        },
      }
    case ClassSetEntryActionType.SET_CLASS_SET_NAME_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          metadata: {
            ...state.domainData.metadata,
            name: action.payload.name,
          },
        },
      }
    case ClassSetEntryActionType.SET_CLASS_SET_REMARKS_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          metadata: {
            ...state.domainData.metadata,
            remarks: action.payload.remarks,
          },
        },
      }
    case ClassSetEntryActionType.SET_CLASS_SET_ID_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetId: action.payload.classSetId,
        },
      }
    case ClassSetEntryActionType.SET_CLASS_SET_STATE_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          classSetEntryState: action.payload.classSetState,
        },
      }
    case ClassSetEntryActionType.SET_IN_PROGRESS_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ClassSetEntryActionType.SET_METADATA_SUB_STATE_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          classSetEntrySubState: {
            ...state.appState.classSetEntrySubState,
            metadataSubState: action.payload.metadataSubState,
          },
        },
      }
    case ClassSetEntryActionType.SET_EXECUTE_SUB_STATE_FOR_CLASS_SET_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          classSetEntrySubState: {
            ...state.appState.classSetEntrySubState,
            executeSubState: action.payload.executeSubState,
          },
        },
      }
    case ClassSetEntryActionType.CLEAR_CLASS_SET_ENTRY_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
