import { Timestamp } from 'state/firebase'

export const ClassSetListActionType = {
  SET_CLASS_SET_LIST_FOR_CLASS_SET_LIST:
    'SET_CLASS_SET_LIST_FOR_CLASS_SET_LIST',
  SET_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST:
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST',
  CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST:
    'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST',
  SET_CLASS_SET_IDS_FOR_CLASS_SET_LIST: 'SET_CLASS_SET_IDS_FOR_CLASS_SET_LIST',
  SET_IN_PROGRESS_FOR_CLASS_SET_LIST: 'SET_IN_PROGRESS_FOR_CLASS_SET_LIST',
  SET_CURRENT_CLASS_SET_LIST_SNAPSHOT_FOR_CLASS_SET_LIST:
    'SET_CURRENT_CLASS_SET_LIST_SNAPSHOT_FOR_CLASS_SET_LIST',
  CLEAR_CLASS_SET_LIST_STATE: 'CLEAR_CLASS_SET_LIST_STATE',
} as const

export interface ClassSet {
  classSetId: string
  classSet: string
  classSetName: string
  remarks?: string
  createdAt: Timestamp
  userGroupId: string
}

export interface ClassSetListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface ClassSetListDomainData {
  currentClassSetList: ClassSet[]
  classSetListDisplayCondition: ClassSetListDisplayCondition
  classSetIds: string[]
  currentClassSetListSnapshot?: () => void
}

export interface ClassSetListAppState {
  inProgress: boolean
}

export interface ClassSetListState {
  domainData: ClassSetListDomainData
  appState: ClassSetListAppState
}
