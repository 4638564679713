import { combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
import { LoginReducer, LoginState } from 'state/ducks/login'
import { BuildDetailReducer, BuildDetailState } from 'state/ducks/buildDetail'
import { BuildEntryState, BuildUploadReducer } from './build'
import { BuildListReducer, BuildListState } from './buildList'
import {
  CustomTrainingReducer,
  CurrentCustomTrainingState,
} from 'state/ducks/customTraining'
import {
  CreateDatasetReducer,
  CreateDatasetState,
} from 'state/ducks/createDataset'
import { DatasetDetail, DatasetDetailReducer } from './datasetDetail'
import {
  AnnotationSetDetailReducer,
  AnnotationSetDetail,
} from './annotationSetDetail'
import { DatasetInfoList, DatasetListReducer } from './datasetList'
import {
  CreateSettingReducer,
  CurrentCreateSettingState,
} from 'state/ducks/createSetting'
import {
  CustomTrainingListReducer,
  CustomTraininglist,
} from 'state/ducks/customTrainingList'
import { InferenceListReducer, InferenceList } from 'state/ducks/inferenceList'
import {
  CurrentInferenceReducer,
  CurrentInferenceState,
} from 'state/ducks/inference'

import {
  CreateImageSetReducer,
  CurrentCreateImageSetState,
} from 'state/ducks/createImageSet'

import { ModelInfoList, ModelListReducer } from 'state/ducks/modelList'
import {
  CustomTrainingDetail,
  CustomTrainingDetailReducer,
} from './customTrainingDetail'
import { InferenceDetail, InferenceDetailReducer } from './inferenceDetail'
import { ModelUploadReducer, ModelUploadState } from './model'
import { ModelDetail, ModelDetailReducer } from './modelDetail'
import { UserSettingState, UserSettingStateReducer } from './userSetting'
import { PasswordUpdateState, PasswordUpdateReducer } from './passwordUpdate'
import { MfaSettingState, MfaSettingReducer } from './mfaSetting'
import { PasswordResetReducer, PasswordResetState } from './passwordReset'
import { AccountEntryReducer, AccountEntryState } from './accountEntry'
import { AccountInfoList, AccountListReducer } from './accountList'
import { AccountDetailReducer, AccountDetail } from './accountDetail'
import { ModelGroupEntryReducer, ModelGroupEntryState } from './modelGroupEntry'
import { AdminModelListReducer, AdminModelInfoList } from './adminModelList'
import {
  ModelGroupListReducer,
  ModelGroupInfoListState,
} from './modelGroupList'
import { ModelGroupDetailReducer, ModelGroupDetail } from './modelGroupDetail'
import { AccountGroups, AccountGroupsReducer } from './accountGroups'
import {
  FeatureDataGroupListReducer,
  FeatureDataGroupInfoListState,
} from './featureDataGroupList'
import {
  FeatureDataGroupDetailReducer,
  FeatureDataGroupDetail,
} from './featureDataGroupDetail'
import {
  FeatureDataInfoList,
  FeatureDataListReducer,
} from 'state/ducks/featureDataList'
import {
  FeatureDataDetailReducer,
  FeatureDataDetail,
} from './featureDataDetail'
import {
  AdminFeatureDataListReducer,
  AdminFeatureDataInfoList,
} from './adminFeatureDataList'
import {
  FeatureDataGroupEntryReducer,
  FeatureDataGroupEntryState,
} from './featureDataGroupEntry'
import { FeatureDataUploadReducer, FeatureDataUploadState } from './featureData'
import {
  FeatureDataGeneratingReducer,
  FeatureDataGeneratingState,
} from './featureDataGenerating'

import {
  FeatureDataGeneratingListReducer,
  FeatureDataGeneratingListState,
} from './featureDataGeneratingList'

import {
  FeatureDataGeneratingDetailReducer,
  FeatureDataGeneratingDetail,
} from './featureDataGeneratingDetail'
import {
  RobotDataAnalysisEntryReducer,
  RobotDataAnalysisEntryState,
} from './robotDataAnalysisEntry'

import {
  DatasetAugmentationListReducer,
  DatasetAugmentationList,
} from 'state/ducks/datasetAugmentationList'
import {
  DatasetAugmentationReducer,
  CurrentDatasetAugmentationState,
} from './datasetAugmentation'
import {
  ScenePresetListReducer,
  ScenePresetListState,
} from 'state/ducks/scenePresetList'
import {
  SceneCameraListReducer,
  SceneCameraListState,
} from 'state/ducks/sceneCameraList'
import {
  SceneCameraDetailReducer,
  SceneCameraDetailState,
} from 'state/ducks/sceneCameraDetail'
import {
  SceneCameraEntryReducer,
  SceneCameraEntryState,
} from 'state/ducks/sceneCameraEntry'
import {
  DatasetAugmentationDetailReducer,
  DatasetAugmentationDetail,
} from './datasetAugmentationDetail'
import {
  RobotDataAnalysisExtractedImagesReducer,
  RobotDataAnalysisExtractedImagesState,
} from './robotDataAnalysisExtractedImages'

import {
  RobotDataAnalysisExtractedImageDetailReducer,
  RobotDataAnalysisExtractedImageDetailState,
} from './robotDataAnalysisExtractedImageDetail'
import {
  FeatureDataTransferringReducer,
  FeatureDataTransferringState,
} from './featureDataTransferring'
import {
  FeatureDataTransferringListReducer,
  FeatureDataTransferringListState,
} from './featureDataTransferringList'
import { SettingDetail, SettingDetailReducer } from './settingDetail'
import { ClassSetListReducer, ClassSetListState } from './classSetList'
import {
  CurrentClassSetEntryState,
  ClassSetEntryReducer,
} from './classSetEntry'

export const history = createBrowserHistory()

export type State = {
  loginState: LoginState
  buildDetailState: BuildDetailState
  buildEntryState: BuildEntryState
  buildListState: BuildListState
  currentCustomTrainingState: CurrentCustomTrainingState
  datasetListState: DatasetInfoList
  createDatasetState: CreateDatasetState
  datasetDetailState: DatasetDetail
  annotationSetDetailState: AnnotationSetDetail
  currentCreateSettingState: CurrentCreateSettingState
  customTrainingListState: CustomTraininglist
  customTrainingDetailState: CustomTrainingDetail
  modelListState: ModelInfoList
  adminModelListState: AdminModelInfoList
  inferenceListState: InferenceList
  inferenceDetailState: InferenceDetail
  currentInferenceState: CurrentInferenceState
  currentCreateImageSetState: CurrentCreateImageSetState
  modelUploadState: ModelUploadState
  modelDetailState: ModelDetail
  userSettingState: UserSettingState
  passwordUpdateState: PasswordUpdateState
  mfaSettingState: MfaSettingState
  passwordResetState: PasswordResetState
  accountEntryState: AccountEntryState
  accountListState: AccountInfoList
  accountDetailState: AccountDetail
  modelGroupEntryState: ModelGroupEntryState
  modelGroupListState: ModelGroupInfoListState
  modelGroupDetailState: ModelGroupDetail
  settingDetailState: SettingDetail
  accountGroupsState: AccountGroups
  featureDataGroupListState: FeatureDataGroupInfoListState
  featureDataGroupDetailState: FeatureDataGroupDetail
  featureDataListState: FeatureDataInfoList
  featureDataDetailState: FeatureDataDetail
  adminFeatureDataListState: AdminFeatureDataInfoList
  featureDataGroupEntryState: FeatureDataGroupEntryState
  featureDataUploadState: FeatureDataUploadState
  featureDataGeneratingState: FeatureDataGeneratingState
  featureDataGeneratingListState: FeatureDataGeneratingListState
  featureDataGeneratingDetailState: FeatureDataGeneratingDetail
  featureDataTransferringListState: FeatureDataTransferringListState
  featureDataTransferringState: FeatureDataTransferringState
  datasetAugmentationListState: DatasetAugmentationList
  currentDatasetAugmentationState: CurrentDatasetAugmentationState
  scenePresetListState: ScenePresetListState
  sceneCameraListState: SceneCameraListState
  sceneCameraDetailState: SceneCameraDetailState
  sceneCameraEntryState: SceneCameraEntryState
  datasetAugmentationDetailState: DatasetAugmentationDetail
  robotDataAnalysisEntryState: RobotDataAnalysisEntryState
  robotDataAnalysisExtractedImagesState: RobotDataAnalysisExtractedImagesState
  robotDataAnalysisExtractedImageDetailState: RobotDataAnalysisExtractedImageDetailState
  classSetListState: ClassSetListState
  classSetEntryState: CurrentClassSetEntryState
}
export const reducers = combineReducers<State>({
  loginState: LoginReducer,
  buildDetailState: BuildDetailReducer,
  buildEntryState: BuildUploadReducer,
  buildListState: BuildListReducer,
  currentCustomTrainingState: CustomTrainingReducer,
  createDatasetState: CreateDatasetReducer,
  datasetDetailState: DatasetDetailReducer,
  annotationSetDetailState: AnnotationSetDetailReducer,
  datasetListState: DatasetListReducer,
  currentCreateSettingState: CreateSettingReducer,
  customTrainingListState: CustomTrainingListReducer,
  customTrainingDetailState: CustomTrainingDetailReducer,
  modelListState: ModelListReducer,
  adminModelListState: AdminModelListReducer,
  inferenceListState: InferenceListReducer,
  inferenceDetailState: InferenceDetailReducer,
  currentInferenceState: CurrentInferenceReducer,
  currentCreateImageSetState: CreateImageSetReducer,
  modelUploadState: ModelUploadReducer,
  modelDetailState: ModelDetailReducer,
  userSettingState: UserSettingStateReducer,
  passwordUpdateState: PasswordUpdateReducer,
  mfaSettingState: MfaSettingReducer,
  passwordResetState: PasswordResetReducer,
  accountEntryState: AccountEntryReducer,
  accountListState: AccountListReducer,
  accountDetailState: AccountDetailReducer,
  modelGroupEntryState: ModelGroupEntryReducer,
  modelGroupListState: ModelGroupListReducer,
  modelGroupDetailState: ModelGroupDetailReducer,
  settingDetailState: SettingDetailReducer,
  accountGroupsState: AccountGroupsReducer,
  featureDataGroupListState: FeatureDataGroupListReducer,
  featureDataGroupDetailState: FeatureDataGroupDetailReducer,
  featureDataListState: FeatureDataListReducer,
  featureDataDetailState: FeatureDataDetailReducer,
  adminFeatureDataListState: AdminFeatureDataListReducer,
  featureDataGroupEntryState: FeatureDataGroupEntryReducer,
  featureDataUploadState: FeatureDataUploadReducer,
  featureDataGeneratingState: FeatureDataGeneratingReducer,
  featureDataGeneratingListState: FeatureDataGeneratingListReducer,
  featureDataGeneratingDetailState: FeatureDataGeneratingDetailReducer,
  featureDataTransferringListState: FeatureDataTransferringListReducer,
  featureDataTransferringState: FeatureDataTransferringReducer,
  datasetAugmentationListState: DatasetAugmentationListReducer,
  currentDatasetAugmentationState: DatasetAugmentationReducer,
  scenePresetListState: ScenePresetListReducer,
  sceneCameraListState: SceneCameraListReducer,
  sceneCameraDetailState: SceneCameraDetailReducer,
  sceneCameraEntryState: SceneCameraEntryReducer,
  datasetAugmentationDetailState: DatasetAugmentationDetailReducer,
  robotDataAnalysisEntryState: RobotDataAnalysisEntryReducer,
  robotDataAnalysisExtractedImagesState:
    RobotDataAnalysisExtractedImagesReducer,
  robotDataAnalysisExtractedImageDetailState:
    RobotDataAnalysisExtractedImageDetailReducer,
  classSetListState: ClassSetListReducer,
  classSetEntryState: ClassSetEntryReducer,
})
