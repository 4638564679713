import { Dispatch } from 'redux'
import { State } from 'state/store'
import { classSetEntryActions } from './actions'
import { ClassSetEntryStateKind, ClassSetEntryStateKindArray } from './types'
import { CreateClassSetApi } from './apis'

export const classSetEntryOperations = {
  /** リストを取得する */
  execute:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(classSetEntryActions.setInProgress(true))
        dispatch(classSetEntryActions.setExecuteSubState('ExecuteInProgress'))

        const createClassSetResponse = await CreateClassSetApi.createClassSet({
          classSets: getState().pages.classSetEntryState.domainData.classSets,
          metadata: {
            name: getState().pages.classSetEntryState.domainData.metadata.name,
            remarks:
              getState().pages.classSetEntryState.domainData.metadata.remarks,
          },
        })

        dispatch(classSetEntryActions.setExecuteSubState('Executed'))
        dispatch(
          classSetEntryActions.setClassSetId(
            createClassSetResponse.data.classSetId
          )
        )
      } catch (error) {
        dispatch(classSetEntryActions.setExecuteSubState('ExecuteError'))
        console.error(error)
      } finally {
        dispatch(classSetEntryActions.setInProgress(false))
      }
    },
  nextStep:
    (currentStep: ClassSetEntryStateKind) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        classSetEntryActions.setClassSetState(
          ClassSetEntryStateKindArray[
            ClassSetEntryStateKindArray.indexOf(currentStep) + 1
          ]
        )
      )
    },
  prevStep:
    (currentStep: ClassSetEntryStateKind) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 'MetadataState':
          dispatch(classSetEntryActions.setMetadataSubState('EmptyRequired'))
          dispatch(classSetEntryActions.setClassSetName(''))
          dispatch(classSetEntryActions.setClassSetRemarks(''))
          break
        case 'ExecuteState':
          break
        default:
          break
      }

      dispatch(
        classSetEntryActions.setClassSetState(
          ClassSetEntryStateKindArray[
            ClassSetEntryStateKindArray.indexOf(currentStep) - 1
          ]
        )
      )
    },
  setClassSetName:
    (classSetName: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(classSetEntryActions.setClassSetName(classSetName))

      if (
        classSetName &&
        getState().pages.classSetEntryState.domainData.metadata.name
      ) {
        dispatch(classSetEntryActions.setMetadataSubState('InputRequired'))
      } else {
        dispatch(classSetEntryActions.setMetadataSubState('EmptyRequired'))
      }
    },
}
