import { ClassSetListAction } from './actions'
import { ClassSetListActionType, ClassSetListState } from './types'

const initialState: ClassSetListState = {
  domainData: {
    currentClassSetList: [],
    classSetListDisplayCondition: {
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'UserGroup',
    },
    classSetIds: [],
    currentClassSetListSnapshot: undefined,
  },
  appState: {
    inProgress: false,
  },
}

export const ClassSetListReducer = (
  state: ClassSetListState = initialState,
  action: ClassSetListAction
): ClassSetListState => {
  switch (action.type) {
    case ClassSetListActionType.SET_CLASS_SET_LIST_FOR_CLASS_SET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentClassSetList: action.payload.currentClassSetList,
        },
      }
    case ClassSetListActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST:
      if (action.payload.classSetDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            classSetListDisplayCondition:
              action.payload.classSetDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case ClassSetListActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetListDisplayCondition: {
            ...initialState.domainData.classSetListDisplayCondition,
          },
        },
      }
    case ClassSetListActionType.SET_IN_PROGRESS_FOR_CLASS_SET_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ClassSetListActionType.SET_CLASS_SET_IDS_FOR_CLASS_SET_LIST:
      if (action.payload.classSetIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            classSetIds: action.payload.classSetIds,
          },
        }
      } else {
        return { ...state }
      }
    case ClassSetListActionType.SET_CURRENT_CLASS_SET_LIST_SNAPSHOT_FOR_CLASS_SET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentClassSetListSnapshot:
            action.payload.currentClassSetListSnapshot,
        },
      }
    case ClassSetListActionType.CLEAR_CLASS_SET_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
