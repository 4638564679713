import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

const timeoutLimitSeconds = 15
const timeoutLimitSecondsForGetLogs = 30

export const FeatureDataGeneratingDetailApi = {
  getFeatureDataFiles: (featureDataId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'feature-data-files',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      featureDataId: featureDataId,
    }),
  getLogs: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-logs',
      {
        timeout: timeoutLimitSecondsForGetLogs * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
    }),
  downloadLog: (
    pipelineJobId: string,
    customJobId: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-download-log',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      pipelineJobId,
      customJobId,
    }),
}
