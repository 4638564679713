import { Timestamp } from 'state/firebase'
import { ResourceLoadingState, TransactionStatusKind } from 'state/utils/types'
import { MlPipelineLogFile } from 'types/StateTypes'

export const CustomTrainingDetailActionType = {
  SET_CURRENT_CUSTOM_TRAINING_DETAIL: 'SET_CURRENT_CUSTOM_TRAINING_DETAIL',
  SET_CUSTOM_TRAINING_DETAIL_STATE: 'SET_CUSTOM_TRAINING_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_DETAIL:
    'SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_DETAIL',
  CLEAR_CUSTOM_TRAINING_DETAIL_STATE: 'CLEAR_CUSTOM_TRAINING_DETAIL_STATE',
  SET_TOAST_INFO: 'SET_TOAST_INFO',
  SET_TRAINED_MODEL_DL_LINKS: 'SET_TRAINED_MODEL_DL_LINKS',
  SET_METRICS_FILES: 'SET_METRICS_FILES',
  SET_ML_PIPELINE_LOG_FILES_FOR_CUSTOM_TRAINING:
    'SET_ML_PIPELINE_LOG_FILES_FOR_CUSTOM_TRAINING',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface Dataset {
  datasetId: string
  datasetName: string
}

interface BaseModel {
  baseModelId: string
  baseModelName: string
  isSharedUserGroupBaseModel: boolean
}

interface InheritedVersion {
  trainedModelGroupId: string
  trainedModelGroupVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  trainedModelId: string
  userGroupId: string
}

interface TrainedModel {
  trainedModelId: string
  trainedModelName: string
}

export interface MediaLink {
  mediaName: string
  mediaUrl: string
  mediaSize: number
}

export interface TrainedModelDlLink {
  linkName: string
  mediaLinks: MediaLink[]
  totalMediaSize: number
}

/**
 * アルゴリズム固有の情報
 */
export interface Extended {
  /** 物体クラス分類情報 */
  objectClassification?: {
    /** クラスセット情報 */
    classSet: {
      /** クラスセットID */
      classSetId: string
      /** クラスセット名 */
      classSetName: string
      /** ユーザーグループID */
      userGroupId: string
    }
    /** セッティング情報 */
    setting: {
      /** 前処理種別 */
      preProcessKind: 'NONE' | 'PRESERVE_ASPECT_RATIO' | 'IGNORE_ASPECT_RATIO'
      /** ベースサイズ */
      baseSize: {
        /** ベースサイズ横 */
        width: number
        /** ベースサイズ縦 */
        height: number
      }
    }
  }
}

export type DestType = 'CustomModel' | 'EdgeImage'
export type StepStatus =
  | 'Pending'
  | 'Starting'
  | 'PreProcessing'
  | 'Training'
  | 'Evaluating'
  | 'PostProcessing'
  | 'Completed'
  | 'Failed'

export interface MlPipelineFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}

interface Setting {
  settingId: string
  settingName: string
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface CurrentCustomTrainingDetail {
  mlPipelineId: string
  mlPipelineName: string
  mlPipelineRemarks: string
  trainingAlgorithm: {
    algorithmName: string
    trainingAlgorithmVersion: {
      displayName: string
      major: number
      minor: number
      patch: number
      preRelease: number
    }
  }
  progress: Progress
  startedAt: Timestamp
  endedAt: Timestamp
  dataset: Dataset
  setting: Setting
  baseModel: BaseModel
  inheritedVersion?: InheritedVersion
  trainedModel: TrainedModel
  destType: DestType
  createdBy: AccountName
  steps: {
    [x: string]: {
      stepId: string
      stepStatus: StepStatus
    }
  }
  extended?: Extended
}

export interface CustomTrainingDetailState {
  mlPipelineDataState: ResourceLoadingState
  trainedModelDlLinkSubState: ResourceLoadingState
  metricsSubState: ResourceLoadingState
  mlPipelineLogSubState: ResourceLoadingState
}

export interface GetModelsFilesResponse {
  data: {
    items: TrainedModelDlLink[]
  }
}

export interface CustomTrainingDetailDomainData {
  currentCustomTrainingDetail?: CurrentCustomTrainingDetail
  metricsFiles: MlPipelineFile[]
  trainedModelDlLinks: TrainedModelDlLink[]
  mlPipelineLogFiles: MlPipelineLogFile[]
}

export interface CustomTrainingDetailAppState {
  customTrainingDetailState: CustomTrainingDetailState
  toastInfo: ToastInfo | undefined
  inProgress: boolean
}

export interface CustomTrainingDetail {
  domainData: CustomTrainingDetailDomainData
  appState: CustomTrainingDetailAppState
}
