import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  CustomTrainingDetailAction,
  customTrainingDetailActions,
  customTrainingDetailOperations,
  MediaLink,
  MlPipelineFile,
} from 'state/ducks/customTrainingDetail'

import { isUndefined } from 'utils/typeguard'
import {
  TrainingIcon,
  StatusProgressBar,
  GlobalLoading,
  CopyableLabel,
  FileDownloadLabel,
  DataDetailItem,
  RunningTimeLabel,
  showToast,
  Toast,
  BreadcrumbsComponent,
} from 'views/components'
import { FileDownloadLabelPropsItems } from 'views/components/molecules/fileDownloadLabel/types'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  convertProgressWord,
  convertProgressColor,
  convertDestType,
  convertByteToMatchUnit,
  handleResourceNotFound,
} from 'views/containers/utils'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
  formatTimeSecByDate,
  lowerThanDateOnly,
} from 'views/components/utils/date'
import { getTrainedModelGroupId } from 'utils/ducks/trainedModelGroup'
import { MlPipelineLogFile } from 'types/StateTypes'

const mapStateToProps = (state: State) => ({
  ...state.pages.customTrainingDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, CustomTrainingDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カスタム学習一覧取得 */
  getCustomTrainingDetail: (mlPipelineId: string) =>
    dispatch(
      customTrainingDetailOperations.getCustomTrainingDetail(mlPipelineId)
    ),
  /** ファイルデータ取得 */
  getFileData: () => dispatch(customTrainingDetailOperations.getFileData()),
  /** Stateのクリア */
  clearCustomTrainingDetailState: () =>
    dispatch(customTrainingDetailActions.clearCustomTrainingDetailState()),
  /** モデルファイルをダウンロードする */
  modelFileDownload: (links: MediaLink[]) =>
    dispatch(customTrainingDetailOperations.modelFileDownload(links)),
  /** メトリクスファイルをダウンロードする */
  metricsFileDownload: (metricsFiles: MlPipelineFile[]) =>
    dispatch(customTrainingDetailOperations.metricsFileDownload(metricsFiles)),
  /** ログファイルをダウンロードする */
  logFileDownload: (logFiles: MlPipelineLogFile[]) =>
    dispatch(customTrainingDetailOperations.logFileDownload(logFiles)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(customTrainingDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  mt2Box: {
    marginTop: '16px',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  fileDataLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
}))

const AlgorithmSpecificContent: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  if (isUndefined(props.domainData.currentCustomTrainingDetail?.extended))
    return <></>

  if (
    !isUndefined(
      props.domainData.currentCustomTrainingDetail?.extended
        .objectClassification
    )
  ) {
    return (
      <Box component={Paper}>
        <Box p={'24px 32px 32px'}>
          <Typography mb={1}>
            {
              props.domainData.currentCustomTrainingDetail?.trainingAlgorithm
                .algorithmName
            }
          </Typography>
          <DataDetailItem
            formHelperText='クラスセット'
            endAdornment={
              <CopyableLabel
                value={
                  props.domainData.currentCustomTrainingDetail?.extended
                    ?.objectClassification.classSet.classSetId ?? ''
                }
              />
            }
            startAdornment={
              props.domainData.currentCustomTrainingDetail?.extended
                ?.objectClassification.classSet.classSetId &&
              props.domainData.currentCustomTrainingDetail?.extended
                .objectClassification.classSet.classSetName ? (
                <Box sx={{ color: 'text.primary' }}>
                  <Typography>
                    {
                      props.domainData.currentCustomTrainingDetail.extended
                        .objectClassification.classSet.classSetName
                    }
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ color: 'text.secondary' }}>
                  <Typography>{NO_NAME}</Typography>
                </Box>
              )
            }
          />
          <Box mt={1}>
            <DataDetailItem
              formHelperText='前処理種別'
              startAdornment={
                props.domainData.currentCustomTrainingDetail?.extended
                  ?.objectClassification.setting.preProcessKind ? (
                  <Box sx={{ color: 'text.primary' }}>
                    <Typography>
                      {
                        props.domainData.currentCustomTrainingDetail.extended
                          .objectClassification.setting.preProcessKind
                      }
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
          </Box>
          <Typography className={classes.label} mt={2}>
            Base Size
          </Typography>
          <Box mt={1} ml={2}>
            <DataDetailItem
              formHelperText='Width'
              startAdornment={
                props.domainData.currentCustomTrainingDetail?.extended
                  ?.objectClassification.setting.baseSize.width ? (
                  <Box sx={{ color: 'text.primary' }}>
                    <Typography>
                      {
                        props.domainData.currentCustomTrainingDetail.extended
                          .objectClassification.setting.baseSize.width
                      }
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
          </Box>
          <Box mt={1} ml={2}>
            <DataDetailItem
              formHelperText='Height'
              startAdornment={
                props.domainData.currentCustomTrainingDetail?.extended
                  ?.objectClassification.setting.baseSize.height ? (
                  <Box sx={{ color: 'text.primary' }}>
                    <Typography>
                      {
                        props.domainData.currentCustomTrainingDetail.extended
                          .objectClassification.setting.baseSize.height
                      }
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return <></>
}

const CustomTrainingDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** 初期実行 */
  useEffect(() => {
    // 表示用詳細取得
    props.getCustomTrainingDetail(
      isDetailPathParams(props.match.params) ? props.match.params.id : ''
    )
    return () => {
      props.clearCustomTrainingDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentCustomTrainingDetail?.mlPipelineId) {
      props.getFileData()
    }
  }, [props.domainData.currentCustomTrainingDetail?.mlPipelineId])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** モデルグループIDを取得 */
  const getTrainedModelGroupIdPath = async (
    userGroupId: string,
    modelId: string
  ) => {
    const modelGroupId = await getTrainedModelGroupId(userGroupId, modelId)
    return modelGroupId
  }

  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.customTrainingDetailState.mlPipelineDataState,
      history
    )
  }, [props.appState.customTrainingDetailState.mlPipelineDataState])

  /** メトリクスファイルの取得エラー時Toast */
  useEffect(() => {
    if (props.appState.customTrainingDetailState.metricsSubState === 'Failed') {
      showErrorToast('メトリクスファイルの取得に失敗しました。')
    }
  }, [props.appState.customTrainingDetailState.metricsSubState])

  /** ログファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.customTrainingDetailState.mlPipelineLogSubState ===
      'Failed'
    ) {
      showErrorToast('ログファイルの取得に失敗しました。')
    }
  }, [props.appState.customTrainingDetailState.mlPipelineLogSubState])

  /** モデルファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.customTrainingDetailState.trainedModelDlLinkSubState ===
      'Failed'
    ) {
      showErrorToast('モデルファイルの取得に失敗しました。')
    }
  }, [props.appState.customTrainingDetailState.trainedModelDlLinkSubState])

  const [nowTab, setNowTab] = useState(0)

  /** ファイルデータ取得中のloading */
  const fileDataLoading = useMemo(() => {
    if (
      props.appState.customTrainingDetailState.metricsSubState ===
        'BeforeLoading' ||
      props.appState.customTrainingDetailState.mlPipelineLogSubState ===
        'BeforeLoading' ||
      props.appState.customTrainingDetailState.trainedModelDlLinkSubState ===
        'BeforeLoading'
    ) {
      if (nowTab === 1) {
        return (
          <Box className={classes.fileDataLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.customTrainingDetailState, nowTab])

  /** urlから取得したmlPipelineId */
  const mlPipelineId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (mlPipelineId === '') {
    console.error('Error Invalid ML Pipeline ID')
    return <></>
  }

  const fileDownloadLabelPropsItems = (
    logFiles: MlPipelineFile[],
    onClick: (files: MlPipelineFile[]) => void
  ): FileDownloadLabelPropsItems[] => {
    return logFiles.map((log) => {
      return {
        timeAndCapacity: `${convertByteToMatchUnit(
          log.fileSize
        )} ${formatDateTimeSec(log.createdAt)}`,
        url: log.fileName,
        onClick: () => onClick([log]),
      } as FileDownloadLabelPropsItems
    })
  }

  const fileDownloadLabelPropsItemsForLog = (
    logFiles: MlPipelineLogFile[],
    onClick: (files: MlPipelineLogFile[]) => void
  ): FileDownloadLabelPropsItems[] => {
    return logFiles.map((log) => {
      return {
        url: log.displayName,
        onClick: () => onClick([log]),
        disableLink: log.pipelineJobId === '',
      } as FileDownloadLabelPropsItems
    })
  }

  const tabItems: TabItems[] = [
    // 実行情報コンテンツ
    {
      label: '実行情報',
      displayInfo: (
        <>
          <Box component={Paper} mb={2}>
            <Box p={'24px 32px 32px'}>
              <Typography mb={1}>共通</Typography>
              <DataDetailItem
                formHelperText='ベースモデル'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentCustomTrainingDetail?.baseModel
                        .baseModelId ?? ''
                    }
                  />
                }
                startAdornment={
                  props.domainData.currentCustomTrainingDetail?.baseModel
                    .baseModelName &&
                  props.domainData.currentCustomTrainingDetail?.baseModel
                    .baseModelId ? (
                    <Link
                      variant='body1'
                      data-testid='base-model-detail'
                      className={classes.link}
                      underline='none'
                      onClick={async () => {
                        const trainedModelGroupId =
                          await getTrainedModelGroupIdPath(
                            props.domainData.currentCustomTrainingDetail
                              ?.baseModel.isSharedUserGroupBaseModel
                              ? props.auth.customClaims.sharedList[0]
                              : props.auth.customClaims.userGroupId,
                            // NOTE: 確実に存在することが保証されている
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            props.domainData.currentCustomTrainingDetail!
                              .baseModel.baseModelId
                          )
                        return history.push(
                          `/model-groups/${trainedModelGroupId}/models/${
                            props.domainData.currentCustomTrainingDetail
                              ?.baseModel.baseModelId
                          }${
                            props.domainData.currentCustomTrainingDetail
                              ?.baseModel.isSharedUserGroupBaseModel
                              ? '?shared-user-group=true'
                              : ''
                          }`
                        )
                      }}
                    >
                      {
                        props.domainData.currentCustomTrainingDetail.baseModel
                          .baseModelName
                      }
                    </Link>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
              <Box mt={1}>
                <DataDetailItem
                  formHelperText='データセットを継承したバージョン'
                  endAdornment={
                    props.domainData.currentCustomTrainingDetail
                      ?.inheritedVersion?.trainedModelId ? (
                      <CopyableLabel
                        value={
                          props.domainData.currentCustomTrainingDetail
                            .inheritedVersion.trainedModelId
                        }
                      />
                    ) : undefined
                  }
                  startAdornment={
                    props.domainData.currentCustomTrainingDetail
                      ?.inheritedVersion?.trainedModelGroupVersion
                      .displayName ? (
                      <Link
                        data-testid='inherited-version'
                        variant='body1'
                        className={classes.link}
                        underline='none'
                        onClick={() => {
                          history.push(
                            `/model-groups/${props.domainData.currentCustomTrainingDetail?.inheritedVersion?.trainedModelGroupId}/models/${props.domainData.currentCustomTrainingDetail?.inheritedVersion?.trainedModelId}`
                          )
                        }}
                      >
                        {props.domainData.currentCustomTrainingDetail
                          .inheritedVersion?.trainedModelGroupVersion
                          .displayName ?? ''}
                      </Link>
                    ) : (
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography>{NO_NAME}</Typography>
                      </Box>
                    )
                  }
                />
              </Box>
              <Box mt={1}>
                <DataDetailItem
                  formHelperText='データセット'
                  endAdornment={
                    <CopyableLabel
                      value={
                        props.domainData.currentCustomTrainingDetail?.dataset
                          .datasetId ?? ''
                      }
                    />
                  }
                  startAdornment={
                    props.domainData.currentCustomTrainingDetail &&
                    props.domainData.currentCustomTrainingDetail.dataset
                      .datasetName ? (
                      <Box sx={{ color: 'text.primary' }}>
                        <Typography>
                          {
                            props.domainData.currentCustomTrainingDetail.dataset
                              .datasetName
                          }
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography>{NO_NAME}</Typography>
                      </Box>
                    )
                  }
                />
              </Box>
              <Box mt={1}>
                <DataDetailItem
                  formHelperText='セッティング'
                  endAdornment={
                    <CopyableLabel
                      value={
                        props.domainData.currentCustomTrainingDetail
                          ? props.domainData.currentCustomTrainingDetail.setting
                              .settingId
                          : ''
                      }
                    />
                  }
                  startAdornment={
                    props.domainData.currentCustomTrainingDetail &&
                    props.domainData.currentCustomTrainingDetail.setting
                      .settingName ? (
                      <Box sx={{ color: 'text.primary' }}>
                        <Typography>
                          {
                            props.domainData.currentCustomTrainingDetail.setting
                              .settingName
                          }
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography>{NO_NAME}</Typography>
                      </Box>
                    )
                  }
                />
              </Box>
              <Box mt={1}>
                <DataDetailItem
                  formHelperText='アウトプット形式'
                  startAdornment={
                    props.domainData.currentCustomTrainingDetail &&
                    props.domainData.currentCustomTrainingDetail.destType ? (
                      <Box sx={{ color: 'text.primary' }}>
                        <Typography>
                          {convertDestType(
                            props.domainData.currentCustomTrainingDetail
                              .destType
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography>{NO_NAME}</Typography>
                      </Box>
                    )
                  }
                />
              </Box>
              {/** NOTE: v0.4.0で対応 */}
              {/* <Box mt={1}>
              <DataDetailItem
                formHelperText='エッジランタイム'
                value={`${
                  props.domainData.currentCustomTrainingDetail
                    .inferenceAlgorithm.algorithmName
                }${
                  props.domainData.currentCustomTrainingDetail
                    .inferenceAlgorithm.algorithmVersion
                }${convertAlgorithmKind(
                  props.domainData.currentCustomTrainingDetail
                    .inferenceAlgorithm.algorithmKind
                )}`}
              />
            </Box> */}
            </Box>
          </Box>
          <AlgorithmSpecificContent {...props} />
        </>
      ),
    },
    // 処理結果コンテンツ
    {
      label: '処理結果',
      displayInfo: (
        <>
          {props.domainData.currentCustomTrainingDetail?.trainedModel
            .trainedModelId !== '' ? (
            <Box component={Paper} sx={{ position: 'relative' }}>
              <Box p={'24px 32px 32px'}>
                <DataDetailItem
                  formHelperText='カスタムモデル'
                  endAdornment={
                    <CopyableLabel
                      value={
                        props.domainData.currentCustomTrainingDetail
                          ? props.domainData.currentCustomTrainingDetail
                              .trainedModel.trainedModelId
                          : ''
                      }
                    />
                  }
                  startAdornment={
                    <Link
                      variant='body1'
                      data-testid='custom-model-detail'
                      className={classes.link}
                      underline='none'
                      onClick={async () => {
                        const trainedModelGroupId =
                          await getTrainedModelGroupIdPath(
                            props.auth.customClaims.userGroupId,
                            // NOTE: 確実に存在することが保証されている
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            props.domainData.currentCustomTrainingDetail!
                              .trainedModel.trainedModelId
                          )
                        return history.push(
                          `/model-groups/${trainedModelGroupId}/models/${props.domainData.currentCustomTrainingDetail?.trainedModel.trainedModelId}`
                        )
                      }}
                    >
                      {props.domainData.currentCustomTrainingDetail
                        ?.trainedModel.trainedModelName !== ''
                        ? props.domainData.currentCustomTrainingDetail
                            ?.trainedModel.trainedModelName
                        : NO_NAME}
                    </Link>
                  }
                />
                <Box mt={1} display='flex' justifyContent='space-between'>
                  <Box display='flex' flexWrap='wrap' maxWidth='800px'>
                    {props.domainData.trainedModelDlLinks.map((info, index) => (
                      <Box mr={2} key={index}>
                        <Button
                          data-testid={`all-download-custom-model-${info.linkName}`}
                          variant='outlined'
                          style={{
                            backgroundColor: '#D9E5FF',
                          }}
                          onClick={() =>
                            props.modelFileDownload(info.mediaLinks)
                          }
                        >
                          <FileDownloadOutlinedIcon
                            style={{ marginRight: '4px' }}
                          />
                          <Box
                            style={{
                              color: '#000099',
                              textTransform: 'none',
                            }}
                          >
                            {`${info.linkName}`}
                          </Box>
                        </Button>
                        <Box className={classes.mediaSizeText}>
                          ({convertByteToMatchUnit(info.totalMediaSize)})
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {props.domainData.trainedModelDlLinks.length > 0 ? (
                    <Box display='flex'>
                      <FileDownloadOutlinedIcon
                        style={{ marginRight: '4px' }}
                      />
                      <Link
                        data-testid='all-download-custom-model'
                        style={{ cursor: 'pointer' }}
                        underline='none'
                        onClick={() => {
                          if (props.domainData.currentCustomTrainingDetail) {
                            let mediaLinks: MediaLink[] = []
                            props.domainData.trainedModelDlLinks.forEach(
                              (link) => {
                                mediaLinks = mediaLinks.concat(link.mediaLinks)
                              }
                            )

                            props.modelFileDownload(mediaLinks)
                          }
                        }}
                      >
                        一括ダウンロード
                      </Link>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Box my={2} component={Paper}>
            <Box p={'24px 32px 32px'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box display='flex'>
                  <Typography component='div'>
                    <h4>メトリクス</h4>
                  </Typography>
                </Box>
                {props.domainData.metricsFiles.length > 0 ? (
                  <Box display='flex'>
                    <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                    <Link
                      data-testid='all-download-metrics'
                      style={{ cursor: 'pointer' }}
                      underline='none'
                      onClick={() =>
                        props.domainData.currentCustomTrainingDetail &&
                        props.metricsFileDownload(props.domainData.metricsFiles)
                      }
                    >
                      一括ダウンロード
                    </Link>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
              <Divider />
              <FileDownloadLabel
                items={fileDownloadLabelPropsItems(
                  props.domainData.metricsFiles,
                  props.metricsFileDownload
                )}
              />
            </Box>
          </Box>
          <Box my={2} component={Paper}>
            <Box p={'24px 32px 32px'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box display='flex'>
                  <Typography component='div'>
                    <h4>ログ</h4>
                  </Typography>
                </Box>
              </div>
              <Divider />
              <FileDownloadLabel
                items={fileDownloadLabelPropsItemsForLog(
                  props.domainData.mlPipelineLogFiles,
                  props.logFileDownload
                )}
              />
            </Box>
          </Box>
          {fileDataLoading}
        </>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={
                  props.domainData.currentCustomTrainingDetail
                    ?.mlPipelineRemarks
                }
                variant='outlined'
                multiline
                disabled
                minRows={5}
                inputProps={{ 'data-testid': 'input-remarks' }}
              />
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      {!isUndefined(props.domainData.currentCustomTrainingDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: 'カスタム学習一覧',
                          path: 'custom-trainings',
                        },
                        {
                          name:
                            props.domainData.currentCustomTrainingDetail
                              .mlPipelineName !== ''
                              ? `${props.domainData.currentCustomTrainingDetail.mlPipelineName}`
                              : `${props.domainData.currentCustomTrainingDetail.mlPipelineId}`,
                          path: `${props.domainData.currentCustomTrainingDetail.mlPipelineId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <TrainingIcon
                        className={classes.pageIcon}
                        data-testid='customTrainingDetailTitleIcon'
                      />
                      <Box
                        height={76}
                        data-testid='custom-training-detail-title'
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography component='div'>
                          <h2
                            style={{
                              maxWidth: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {
                              props.domainData.currentCustomTrainingDetail
                                .mlPipelineName
                            }
                          </h2>
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={mlPipelineId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                      <Box width='150px'>
                        <StatusProgressBar
                          status={convertProgressWord(
                            props.domainData.currentCustomTrainingDetail
                              .progress.transactionStatus
                          )}
                          progressRate={
                            props.domainData.currentCustomTrainingDetail
                              .progress.progressRate
                          }
                          progressColor={convertProgressColor(
                            props.domainData.currentCustomTrainingDetail
                              .progress.transactionStatus
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box p={1}>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' alignItems='center'>
                        <HandymanIcon
                          style={{ marginRight: '4px' }}
                          sx={{ color: 'text.secondary' }}
                        />
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography component='div'>
                            <h4>
                              {`${props.domainData.currentCustomTrainingDetail.trainingAlgorithm.algorithmName} v 
                            ${props.domainData.currentCustomTrainingDetail.trainingAlgorithm.trainingAlgorithmVersion.displayName}`}
                            </h4>
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' mb={1}>
                        <TimerIcon style={{ marginRight: '4px' }} />
                        {isUndefined(
                          props.domainData.currentCustomTrainingDetail.endedAt
                        ) ? (
                          // endAtがundefinedの場合
                          <Box alignItems='center' display='flex'>
                            <Typography>{`${formatDateTimeSec(
                              props.domainData.currentCustomTrainingDetail.startedAt.toDate()
                            )} ~ `}</Typography>
                            (
                            <RunningTimeLabel
                              startedAt={props.domainData.currentCustomTrainingDetail.startedAt.toDate()}
                            />
                            )
                          </Box>
                        ) : lowerThanDateOnly(
                            props.domainData.currentCustomTrainingDetail.startedAt.toDate(),
                            props.domainData.currentCustomTrainingDetail.endedAt.toDate()
                          ) ? (
                          // 日付が同じ場合
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentCustomTrainingDetail.startedAt.toDate()
                          )} ~ ${formatTimeSecByDate(
                            props.domainData.currentCustomTrainingDetail.endedAt.toDate()
                          )}(${formatTimeSecByMillSecond(
                            props.domainData.currentCustomTrainingDetail.endedAt
                              .toDate()
                              .getTime() -
                              props.domainData.currentCustomTrainingDetail.startedAt
                                .toDate()
                                .getTime()
                          )})`}</Typography>
                        ) : (
                          // 日付が違う場合
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentCustomTrainingDetail.startedAt.toDate()
                          )} ~ ${formatDateTimeSec(
                            props.domainData.currentCustomTrainingDetail.endedAt.toDate()
                          )}(${formatTimeSecByMillSecond(
                            props.domainData.currentCustomTrainingDetail.endedAt
                              .toDate()
                              .getTime() -
                              props.domainData.currentCustomTrainingDetail.startedAt
                                .toDate()
                                .getTime()
                          )})`}</Typography>
                        )}
                      </Box>
                      <Box display='flex'>
                        {typeof props.domainData.currentCustomTrainingDetail
                          ?.createdBy === 'string' ? (
                          <Typography>
                            {
                              props.domainData.currentCustomTrainingDetail
                                ?.createdBy
                            }
                          </Typography>
                        ) : (
                          <Typography>
                            {
                              props.domainData.currentCustomTrainingDetail
                                ?.createdBy.firstName
                            }{' '}
                            {
                              props.domainData.currentCustomTrainingDetail
                                ?.createdBy.familyName
                            }
                          </Typography>
                        )}
                      </Box>
                    </div>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1200px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const CustomTrainingDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomTrainingDetail))
