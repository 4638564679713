import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  DatasetAugmentationDetailActionType,
  CurrentDatasetAugmentationDetail,
  DatasetAugmentationDetailState,
  ToastInfo,
} from './types'
import { MlPipelineLogFile } from 'types/StateTypes'

export type DatasetAugmentationDetailAction = ActionsUnion<
  typeof datasetAugmentationDetailActions
>

export const datasetAugmentationDetailActions = {
  setCurrentDatasetAugmentationDetail: (
    currentDatasetAugmentationDetail: CurrentDatasetAugmentationDetail
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_AUGMENTATION_DETAIL',
    { currentDatasetAugmentationDetail: CurrentDatasetAugmentationDetail }
  > =>
    CreateAction(
      DatasetAugmentationDetailActionType.SET_CURRENT_DATASET_AUGMENTATION_DETAIL,
      {
        currentDatasetAugmentationDetail,
      }
    ),
  setDatasetAugmentationDetailState: (
    datasetAugmentationDetailState: DatasetAugmentationDetailState
  ): ActionWithPayload<
    'SET_DATASET_AUGMENTATION_DETAIL_STATE',
    { datasetAugmentationDetailState: DatasetAugmentationDetailState }
  > =>
    CreateAction(
      DatasetAugmentationDetailActionType.SET_DATASET_AUGMENTATION_DETAIL_STATE,
      {
        datasetAugmentationDetailState,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      DatasetAugmentationDetailActionType.SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_DETAIL,
      {
        inProgress,
      }
    ),
  clearDatasetAugmentationDetailState:
    (): Action<'CLEAR_DATASET_AUGMENTATION_DETAIL_STATE'> =>
      CreateAction(
        DatasetAugmentationDetailActionType.CLEAR_DATASET_AUGMENTATION_DETAIL_STATE
      ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_DATASET_AUGMENTATION_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      DatasetAugmentationDetailActionType.SET_TOAST_INFO_FOR_DATASET_AUGMENTATION_DETAIL,
      {
        toastInfo,
      }
    ),
  setMlPipelineLogFiles: (
    mlPipelineLogFiles: MlPipelineLogFile[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LOG_FILES_FOR_DATASET_AUGMENTATION',
    { mlPipelineLogFiles: MlPipelineLogFile[] }
  > =>
    CreateAction(
      DatasetAugmentationDetailActionType.SET_ML_PIPELINE_LOG_FILES_FOR_DATASET_AUGMENTATION,
      {
        mlPipelineLogFiles,
      }
    ),
  setSelectedGroupedDataId: (
    selectedGroupedDataId?: string
  ): ActionWithPayload<
    'SET_SELECTED_GROUPED_DATA_ID_DATASET_AUGMENTATION_DETAIL',
    { selectedGroupedDataId?: string }
  > =>
    CreateAction(
      DatasetAugmentationDetailActionType.SET_SELECTED_GROUPED_DATA_ID_DATASET_AUGMENTATION_DETAIL,
      {
        selectedGroupedDataId,
      }
    ),
}
