import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import { FileDownloadLabelProps } from './types'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  icon: {
    marginRight: theme.spacing(0.4),
  },
  timeAndCapacity: {
    whiteSpace: 'nowrap',
  },
}))

export const FileDownloadLabel: React.FC<FileDownloadLabelProps> = (
  props: FileDownloadLabelProps
) => {
  const { classes } = useStyles()

  return (
    <>
      {props.items.map((item, index) => (
        <Box
          display='flex'
          alignItems='center'
          mt={1}
          padding={1}
          border={1}
          borderRadius={2}
          borderColor={'#eee'}
          key={index}
        >
          {item.disableLink ? (
            <>
              <Typography>{item.url}</Typography>
              {item.timeAndCapacity && (
                <Box ml={1} className={classes.timeAndCapacity}>
                  {item.timeAndCapacity}
                </Box>
              )}
            </>
          ) : (
            <>
              <FileDownloadOutlinedIcon
                fontSize='small'
                className={classes.icon}
              />
              <Link
                data-testid={`download-log-${item.url}`}
                style={{ cursor: 'pointer' }}
                underline='none'
                onClick={item.onClick}
              >
                {item.url}
              </Link>
              {item.timeAndCapacity && (
                <Box ml={1} className={classes.timeAndCapacity}>
                  {item.timeAndCapacity}
                </Box>
              )}
            </>
          )}
        </Box>
      ))}
    </>
  )
}
