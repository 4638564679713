import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  FeatureDataGeneratingDetailAction,
  featureDataGeneratingDetailActions,
  featureDataGeneratingDetailOperations,
  MediaLink,
} from 'state/ducks/featureDataGeneratingDetail'

import { isUndefined } from 'utils/typeguard'
import {
  TrainingIcon,
  StatusProgressBar,
  GlobalLoading,
  CopyableLabel,
  FileDownloadLabel,
  DataDetailItem,
  RunningTimeLabel,
  showToast,
  Toast,
  BreadcrumbsComponent,
} from 'views/components'
import { FileDownloadLabelPropsItems } from 'views/components/molecules/fileDownloadLabel/types'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  convertProgressWord,
  convertProgressColor,
  convertDestTypeForFeatureData,
  convertByteToMatchUnit,
  handleResourceNotFound,
} from 'views/containers/utils'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
  formatTimeSecByDate,
  lowerThanDateOnly,
} from 'views/components/utils/date'
import { getFeatureDataGroupId } from 'utils/ducks/featureDataGroup'
import { MlPipelineLogFile } from 'types/StateTypes'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataGeneratingDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataGeneratingDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データ生成詳細取得 */
  getFeatureDataGeneratingDetail: (mlPipelineId: string) =>
    dispatch(
      featureDataGeneratingDetailOperations.getFeatureDataGeneratingDetail(
        mlPipelineId
      )
    ),
  /** ファイルデータ取得 */
  getFileData: () =>
    dispatch(featureDataGeneratingDetailOperations.getFileData()),
  /** Stateのクリア */
  clearFeatureDataGeneratingDetailState: () =>
    dispatch(
      featureDataGeneratingDetailActions.clearFeatureDataGeneratingDetailState()
    ),
  /** 特徴量データファイルをダウンロードする */
  featureDataFileDownload: (links: MediaLink[]) =>
    dispatch(
      featureDataGeneratingDetailOperations.featureDataFileDownload(links)
    ),
  /** ログファイルをダウンロードする */
  logFileDownload: (logFiles: MlPipelineLogFile[]) =>
    dispatch(featureDataGeneratingDetailOperations.logFileDownload(logFiles)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(featureDataGeneratingDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  mt2Box: {
    marginTop: '16px',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  fileDataLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))

const FeatureDataGeneratingDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** 初期実行 */
  useEffect(() => {
    // 表示用詳細取得
    props.getFeatureDataGeneratingDetail(
      isDetailPathParams(props.match.params) ? props.match.params.id : ''
    )
    return () => {
      props.clearFeatureDataGeneratingDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentFeatureDataGeneratingDetail?.mlPipelineId) {
      props.getFileData()
    }
  }, [props.domainData.currentFeatureDataGeneratingDetail?.mlPipelineId])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** 特徴量データグループIDを取得 */
  const getFeatureDataGroupIdPath = async (
    userGroupId: string,
    featureDataId: string
  ) => {
    const featureDataGroupId = await getFeatureDataGroupId(
      userGroupId,
      featureDataId
    )
    return featureDataGroupId
  }

  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.featureDataGeneratingDetailState.mlPipelineDataState,
      history
    )
  }, [props.appState.featureDataGeneratingDetailState.mlPipelineDataState])

  /** ログファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.featureDataGeneratingDetailState.mlPipelineLogSubState ===
      'Failed'
    ) {
      showErrorToast('ログファイルの取得に失敗しました。')
    }
  }, [props.appState.featureDataGeneratingDetailState.mlPipelineLogSubState])

  /** 特徴量データファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.featureDataGeneratingDetailState
        .featureDataDlLinkSubState === 'Failed'
    ) {
      showErrorToast('特徴量データファイルの取得に失敗しました。')
    }
  }, [
    props.appState.featureDataGeneratingDetailState.featureDataDlLinkSubState,
  ])

  const [nowTab, setNowTab] = useState(0)

  /** ファイルデータ取得中のloading */
  const fileDataLoading = useMemo(() => {
    if (
      props.appState.featureDataGeneratingDetailState.mlPipelineLogSubState ===
        'BeforeLoading' ||
      props.appState.featureDataGeneratingDetailState
        .featureDataDlLinkSubState === 'BeforeLoading'
    ) {
      if (nowTab === 1) {
        return (
          <Box className={classes.fileDataLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.featureDataGeneratingDetailState, nowTab])

  /** urlから取得したmlPipelineId */
  const mlPipelineId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (mlPipelineId === '') {
    console.error('Error Invalid ML Pipeline ID')
    return <></>
  }

  const fileDownloadLabelPropsItems = (
    logFiles: MlPipelineLogFile[]
  ): FileDownloadLabelPropsItems[] => {
    return logFiles.map((log) => {
      return {
        url: log.displayName,
        onClick: () => props.logFileDownload([log]),
        disableLink: log.pipelineJobId === '',
      } as FileDownloadLabelPropsItems
    })
  }

  const tabItems: TabItems[] = [
    // 実行情報コンテンツ
    {
      label: '実行情報',
      displayInfo: (
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='データセット'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentFeatureDataGeneratingDetail
                        ?.dataset.datasetId ?? ''
                    }
                  />
                }
                startAdornment={
                  props.domainData.currentFeatureDataGeneratingDetail &&
                  props.domainData.currentFeatureDataGeneratingDetail.dataset
                    .datasetName ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentFeatureDataGeneratingDetail
                            .dataset.datasetName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='セッティング'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentFeatureDataGeneratingDetail
                        ? props.domainData.currentFeatureDataGeneratingDetail
                            .setting.settingId
                        : ''
                    }
                  />
                }
                startAdornment={
                  props.domainData.currentFeatureDataGeneratingDetail &&
                  props.domainData.currentFeatureDataGeneratingDetail.setting
                    .settingName ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentFeatureDataGeneratingDetail
                            .setting.settingName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='アウトプット形式'
                startAdornment={
                  props.domainData.currentFeatureDataGeneratingDetail &&
                  props.domainData.currentFeatureDataGeneratingDetail
                    .destType ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {convertDestTypeForFeatureData(
                          props.domainData.currentFeatureDataGeneratingDetail
                            .destType
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      ),
    },
    // 処理結果コンテンツ
    {
      label: '処理結果',
      displayInfo: (
        <>
          {props.domainData.currentFeatureDataGeneratingDetail?.featureData
            .featureDataId !== '' ? (
            <Box component={Paper}>
              <Box p={'24px 32px 32px'}>
                <DataDetailItem
                  formHelperText='カスタム特徴量データ'
                  endAdornment={
                    <CopyableLabel
                      value={
                        props.domainData.currentFeatureDataGeneratingDetail
                          ? props.domainData.currentFeatureDataGeneratingDetail
                              .featureData.featureDataId
                          : ''
                      }
                    />
                  }
                  startAdornment={
                    <Link
                      variant='body1'
                      data-testid='feature-data-detail'
                      className={classes.link}
                      underline='none'
                      onClick={async () => {
                        const featureDataGroupId =
                          await getFeatureDataGroupIdPath(
                            props.auth.customClaims.userGroupId,
                            // NOTE: 確実に存在することが保証されている
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            props.domainData.currentFeatureDataGeneratingDetail!
                              .featureData.featureDataId
                          )
                        return history.push(
                          `/feature-data-groups/${featureDataGroupId}/feature-data/${props.domainData.currentFeatureDataGeneratingDetail?.featureData.featureDataId}`
                        )
                      }}
                    >
                      {props.domainData.currentFeatureDataGeneratingDetail
                        ?.featureData.featureDataName !== ''
                        ? props.domainData.currentFeatureDataGeneratingDetail
                            ?.featureData.featureDataName
                        : NO_NAME}
                    </Link>
                  }
                />
                <Box mt={1} display='flex' justifyContent='space-between'>
                  <Box display='flex' flexWrap='wrap' maxWidth='800px'>
                    {props.domainData.featureDataDlLinks.map((info, index) => (
                      <Box mr={2} key={index}>
                        <Button
                          data-testid={`all-download-feature-data-${info.linkName}`}
                          variant='outlined'
                          style={{
                            backgroundColor: '#D9E5FF',
                          }}
                          onClick={() =>
                            props.featureDataFileDownload(info.mediaLinks)
                          }
                        >
                          <FileDownloadOutlinedIcon
                            style={{ marginRight: '4px' }}
                          />
                          <Box
                            style={{
                              color: '#000099',
                              textTransform: 'none',
                            }}
                          >
                            {`${info.linkName}`}
                          </Box>
                        </Button>
                        <Box className={classes.mediaSizeText}>
                          ({convertByteToMatchUnit(info.totalMediaSize)})
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {props.domainData.featureDataDlLinks.length > 0 ? (
                    <Box display='flex'>
                      <FileDownloadOutlinedIcon
                        style={{ marginRight: '4px' }}
                      />
                      <Link
                        data-testid='all-download-feature-data'
                        style={{ cursor: 'pointer' }}
                        underline='none'
                        onClick={() => {
                          if (
                            props.domainData.currentFeatureDataGeneratingDetail
                          ) {
                            let mediaLinks: MediaLink[] = []
                            props.domainData.featureDataDlLinks.forEach(
                              (link) => {
                                mediaLinks = mediaLinks.concat(link.mediaLinks)
                              }
                            )

                            props.featureDataFileDownload(mediaLinks)
                          }
                        }}
                      >
                        一括ダウンロード
                      </Link>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Box my={2} component={Paper}>
            <Box p={'24px 32px 32px'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box display='flex'>
                  <Typography component='div'>
                    <h4>ログ</h4>
                  </Typography>
                </Box>
              </div>
              <Divider />
              <FileDownloadLabel
                items={fileDownloadLabelPropsItems(
                  props.domainData.mlPipelineLogFiles
                )}
              />
            </Box>
          </Box>
        </>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={
                  props.domainData.currentFeatureDataGeneratingDetail
                    ?.mlPipelineRemarks
                }
                variant='outlined'
                multiline
                disabled
                minRows={5}
                inputProps={{ 'data-testid': 'input-remarks' }}
              />
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      {!isUndefined(props.domainData.currentFeatureDataGeneratingDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: '特徴量データ生成一覧',
                          path: 'feature-data-generatings',
                        },
                        {
                          name:
                            props.domainData.currentFeatureDataGeneratingDetail
                              .mlPipelineName !== ''
                              ? `${props.domainData.currentFeatureDataGeneratingDetail.mlPipelineName}`
                              : `${props.domainData.currentFeatureDataGeneratingDetail.mlPipelineId}`,
                          path: `${props.domainData.currentFeatureDataGeneratingDetail.mlPipelineId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <TrainingIcon
                        className={classes.pageIcon}
                        data-testid='featureDataGeneratingDetailTitleIcon'
                      />
                      <Box
                        height={76}
                        data-testid='feature-data-generating-detail-title'
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography component='div'>
                          <h2
                            style={{
                              maxWidth: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {
                              props.domainData
                                .currentFeatureDataGeneratingDetail
                                .mlPipelineName
                            }
                          </h2>
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={mlPipelineId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                      <Box width='150px'>
                        <StatusProgressBar
                          status={convertProgressWord(
                            props.domainData.currentFeatureDataGeneratingDetail
                              .progress.transactionStatus
                          )}
                          progressRate={
                            props.domainData.currentFeatureDataGeneratingDetail
                              .progress.progressRate
                          }
                          progressColor={convertProgressColor(
                            props.domainData.currentFeatureDataGeneratingDetail
                              .progress.transactionStatus
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box p={1}>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' alignItems='center'>
                        <HandymanIcon
                          style={{ marginRight: '4px' }}
                          sx={{ color: 'text.secondary' }}
                        />
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography component='div'>
                            <h4>
                              {`${props.domainData.currentFeatureDataGeneratingDetail.trainingAlgorithm.algorithmName} v 
                            ${props.domainData.currentFeatureDataGeneratingDetail.trainingAlgorithm.trainingAlgorithmVersion.displayName}`}
                            </h4>
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' mb={1}>
                        <TimerIcon style={{ marginRight: '4px' }} />
                        {isUndefined(
                          props.domainData.currentFeatureDataGeneratingDetail
                            .endedAt
                        ) ? (
                          // endAtがundefinedの場合
                          <Box alignItems='center' display='flex'>
                            <Typography>{`${formatDateTimeSec(
                              props.domainData.currentFeatureDataGeneratingDetail.startedAt.toDate()
                            )} ~ `}</Typography>
                            (
                            <RunningTimeLabel
                              startedAt={props.domainData.currentFeatureDataGeneratingDetail.startedAt.toDate()}
                            />
                            )
                          </Box>
                        ) : lowerThanDateOnly(
                            props.domainData.currentFeatureDataGeneratingDetail.startedAt.toDate(),
                            props.domainData.currentFeatureDataGeneratingDetail.endedAt.toDate()
                          ) ? (
                          // 日付が同じ場合
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentFeatureDataGeneratingDetail.startedAt.toDate()
                          )} ~ ${formatTimeSecByDate(
                            props.domainData.currentFeatureDataGeneratingDetail.endedAt.toDate()
                          )}(${formatTimeSecByMillSecond(
                            props.domainData.currentFeatureDataGeneratingDetail.endedAt
                              .toDate()
                              .getTime() -
                              props.domainData.currentFeatureDataGeneratingDetail.startedAt
                                .toDate()
                                .getTime()
                          )})`}</Typography>
                        ) : (
                          // 日付が違う場合
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentFeatureDataGeneratingDetail.startedAt.toDate()
                          )} ~ ${formatDateTimeSec(
                            props.domainData.currentFeatureDataGeneratingDetail.endedAt.toDate()
                          )}(${formatTimeSecByMillSecond(
                            props.domainData.currentFeatureDataGeneratingDetail.endedAt
                              .toDate()
                              .getTime() -
                              props.domainData.currentFeatureDataGeneratingDetail.startedAt
                                .toDate()
                                .getTime()
                          )})`}</Typography>
                        )}
                      </Box>
                      <Box display='flex'>
                        {typeof props.domainData
                          .currentFeatureDataGeneratingDetail?.createdBy ===
                        'string' ? (
                          <Typography>
                            {
                              props.domainData
                                .currentFeatureDataGeneratingDetail?.createdBy
                            }
                          </Typography>
                        ) : (
                          <Typography>
                            {
                              props.domainData
                                .currentFeatureDataGeneratingDetail?.createdBy
                                .firstName
                            }{' '}
                            {
                              props.domainData
                                .currentFeatureDataGeneratingDetail?.createdBy
                                .familyName
                            }
                          </Typography>
                        )}
                      </Box>
                    </div>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1200px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      {fileDataLoading}
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const FeatureDataGeneratingDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataGeneratingDetail))
