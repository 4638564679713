import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import { State } from 'state/store'

import {
  ClassSetEntryAction,
  classSetEntryActions,
} from 'state/ducks/classSetEntry/actions'
import { classSetEntryOperations } from 'state/ducks/classSetEntry/operations'
import {
  ClassSetEntryStateKind,
  ClassSetEntryStateKindArray,
} from 'state/ducks/classSetEntry/types'
import {
  BreadcrumbsComponent,
  CommonCompleteDialog,
  CommonStepper,
  ConfirmViewerDialog,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  MetadataInput,
} from 'views/components'
import { AddableTextFields } from 'views/components/organisms/addableTextFields'

const mapStateToProps = (state: State) => ({
  ...state.pages.classSetEntryState,
  ...state.app.domainData.authedUser,
})
type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ClassSetEntryAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setClassSet: (classSet: string[]) =>
    dispatch(classSetEntryActions.setClassSet(classSet)),
  clearClassSetEntryState: () =>
    dispatch(classSetEntryActions.clearClassSetEntryState()),
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: ClassSetEntryStateKind) => {
    if (
      ClassSetEntryStateKindArray.indexOf(currentStep) >=
      ClassSetEntryStateKindArray.length - 1
    ) {
      /** クラスセット作成開始 */
      dispatch(classSetEntryOperations.execute())
    } else {
      dispatch(classSetEntryOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: ClassSetEntryStateKind) => {
    dispatch(classSetEntryOperations.prevStep(currentStep))
  },
  setClassSetName: (name: string) =>
    dispatch(classSetEntryOperations.setClassSetName(name)),
  setClassSetRemarks: (remarks: string) =>
    dispatch(classSetEntryActions.setClassSetRemarks(remarks)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
}))

/** ステップの名称 */
const STEP_NAMES = ['クラスセット', 'メタデータ', '確認']

/** クラスセット作成失敗時メッセージ */
const ClassSetEntryErrorMessage = (props: Props): JSX.Element => {
  const errorMessages: string[] = []
  if (props.appState.classSetEntrySubState.executeSubState === 'ExecuteError') {
    errorMessages.push('クラスセットの作成に失敗しました。')
    return <ErrorMessage title='' targets={errorMessages} />
  } else return <></>
}

const ClassSetEntryInfoConfirmView: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>クラスセット情報</Typography>
      <Box mt={1}>
        <Box mt={2}>
          <TextField
            id='class-set'
            className={classes.textField}
            variant='standard'
            label='クラスセット'
            value={props.domainData.classSets.join(', ')}
          />
        </Box>
      </Box>
    </Box>
  )
}

const ClassSetEntryMetadataInfoConfirmView: React.FC<Props> = (
  props: Props
) => {
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>クラスセット作成情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: 'クラスセット名',
            value: props.domainData.metadata.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.metadata.remarks,
            readOnly: true,
            variant: 'standard',
            rowNum: 0,
          }}
          textFieldSpace={0}
          helperText=''
          data-testid={'class-set-entry-metadata'}
        />
      </Box>
    </Box>
  )
}

/**
 * クラスセットステップのクラスセット入力部分のコンポーネント
 */
const ClassSetList: FC<Props> = (props) => {
  /** クラスセットのクラス追加ボタンクリック時の動作 */
  const onClickAddButton = useCallback(() => {
    const newClassSet = [...props.domainData.classSets]
    newClassSet.push('')
    props.setClassSet(newClassSet)
  }, [props.domainData.classSets])

  /** クラスセットのクラス */
  const onChangeClassSetItem = useCallback(
    (value: string, index: number) => {
      const newClassSet = [...props.domainData.classSets]
      newClassSet[index] = value
      props.setClassSet(newClassSet)
    },
    [props.domainData.classSets]
  )

  const onClickClearButton = useCallback(
    (index: number) => {
      const newClassSet = [...props.domainData.classSets]
      newClassSet.splice(index, 1)
      props.setClassSet(newClassSet)
    },
    [props.domainData.classSets]
  )

  const errorMessages = useMemo<string[]>(
    () =>
      props.domainData.classSets.map((item) =>
        item &&
        props.domainData.classSets.filter(
          (classSetItem) => classSetItem === item
        ).length >= 2
          ? 'クラス名が不正です。'
          : ''
      ),
    [props.domainData.classSets]
  )

  return (
    <AddableTextFields
      items={props.domainData.classSets}
      errorMessages={errorMessages}
      onChange={onChangeClassSetItem}
      onClickClearButton={onClickClearButton}
      addButton={{
        label: 'クラスを追加する',
        onClick: onClickAddButton,
      }}
    />
  )
}

export const ClassSetEntry = (props: Props) => {
  const { classes } = useStyles()

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      props.clearClassSetEntryState()
    }
  }, [])

  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      ClassSetEntryStateKindArray.indexOf(props.appState.classSetEntryState),
    [props.appState.classSetEntryState]
  )

  /** クラスセット名更新処理 */
  const onChangeClassSetName = (name: string) => {
    props.setClassSetName(name)
  }

  /** クラスセットの備考更新処理 */
  const onChangeClassSetRemarks = (remarks: string) => {
    props.setClassSetRemarks(remarks)
  }

  const handleCloseDialog = () => {
    props.history.push('/class-sets')
  }

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.classSetEntryState) {
      case 'ClassSetState':
        return (
          props.domainData.classSets.every((item) => item !== '') &&
          props.domainData.classSets.length >= 2 &&
          new Set(props.domainData.classSets).size ===
            props.domainData.classSets.length
        )

      case 'MetadataState':
        return (
          props.appState.classSetEntrySubState.metadataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.classSetEntryState,
    props.appState.classSetEntrySubState.metadataSubState,
    props.domainData.classSets,
  ])

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.classSetEntryState) {
      case 'ClassSetState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <ClassSetList {...props} />
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'MetadataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'}>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'クラスセット作成情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: 'クラスセット名',
                    value: props.domainData.metadata.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeClassSetName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.metadata.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeClassSetRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'class-set-entry-input'}
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <>
            <ClassSetEntryErrorMessage {...props} />
            <ClassSetEntryInfoConfirmView {...props} />
            <ClassSetEntryMetadataInfoConfirmView {...props} />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <div className={classes.container}>
      <Box pl={7}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'クラスセット一覧',
              path: 'class-sets',
            },
            {
              name: 'クラスセット作成',
              path: 'entry',
            },
          ]}
        />
      </Box>
      <div className={classes.head}>
        <div className={classes.flexAndBetween}>
          <h2
            className={classes.pageTitle}
            data-testid='feature-data-generating-title'
          >
            クラスセット
          </h2>
          <div className={classes.confirmButton}>
            <Tooltip title='設定情報' placement='bottom'>
              <IconButton
                onClick={() => {
                  setIsOpenConfirmDialog(true)
                }}
              >
                <ReceiptLongIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={classes.stepper}>
          <CommonStepper
            stepLabels={STEP_NAMES}
            activeStepIndex={currentStep}
          />
        </div>
      </div>
      <div className={classes.content} ref={contentRef}>
        {getStepContent(props)}
      </div>
      <div className={classes.footerButtons}>
        <div className={classes.footer}>
          {props.appState.classSetEntryState !== 'ClassSetState' ? (
            <Button
              variant='contained'
              color='primary'
              disabled={false}
              onClick={() => props.prevStep(props.appState.classSetEntryState)}
              className={classes.leftButton}
            >
              {'戻る'}
            </Button>
          ) : (
            <div></div>
          )}
          <Button
            data-testid='next-step'
            variant='contained'
            color='primary'
            disabled={!enableNextButton}
            onClick={() => props.nextStep(props.appState.classSetEntryState)}
            className={classes.rightButton}
          >
            {props.appState.classSetEntryState === 'ExecuteState'
              ? '開始'
              : '次へ'}
          </Button>
        </div>
      </div>
      <CommonCompleteDialog
        open={
          props.appState.classSetEntrySubState.executeSubState === 'Executed' &&
          props.domainData.classSetId !== undefined &&
          props.domainData.classSetId !== ''
        }
        value={props.domainData.classSetId ?? ''}
        handleClose={handleCloseDialog}
        label={'クラスセットID'}
        dialogText={'正常にクラスセットを作成しました。'}
        data-testid={'class-set-entry-complete-dialog'}
      />
      <ConfirmViewerDialog
        maxWidth='md'
        open={isOpenConfirmDialog}
        message={
          <Box width='630px'>
            <Box display='flex'>
              <Box display='flex' paddingTop={2}>
                <Checkbox
                  sx={
                    props.domainData.classSets.length >= 2 &&
                    props.domainData.classSets.every((item) => item !== '') &&
                    new Set(props.domainData.classSets).size ===
                      props.domainData.classSets.length
                      ? {
                          color: 'blue',
                          '&.Mui-checked': {
                            color: 'blue',
                          },
                        }
                      : undefined
                  }
                  checkedIcon={<CheckCircleIcon />}
                  disabled
                  checked
                />
              </Box>
              <ClassSetEntryInfoConfirmView {...props} />
            </Box>
            <Box display='flex'>
              <Box display='flex' paddingTop={2}>
                <Checkbox
                  sx={
                    props.domainData.metadata.name !== ''
                      ? {
                          color: 'blue',
                          '&.Mui-checked': {
                            color: 'blue',
                          },
                        }
                      : undefined
                  }
                  checkedIcon={<CheckCircleIcon />}
                  disabled
                  checked
                />
              </Box>
              <ClassSetEntryMetadataInfoConfirmView {...props} />
            </Box>
          </Box>
        }
        handleClose={() => setIsOpenConfirmDialog(false)}
      />

      <GlobalLoading open={props.appState.inProgress} />
    </div>
  )
}

export const ClassSetEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClassSetEntry))
