import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Add from '@mui/icons-material/Add'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { State } from 'state/store'
import {
  ClassSet,
  ClassSetListAction,
  classSetListActions,
  ClassSetListDisplayCondition,
  classSetListOperations,
} from 'state/ducks/classSetList'
import { isUndefined } from 'utils/typeguard'
import {
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  BreadcrumbsComponent,
  ClassSetIcon,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { getUserGroupKindList } from 'views/containers/utils'

const mapStateToProps = (state: State) => ({
  ...state.pages.classSetListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ClassSetListAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** クラスセット一覧取得 */
  getClassSetList: () => dispatch(classSetListOperations.getClassSetList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: ClassSetListDisplayCondition) =>
    dispatch(
      classSetListActions.setClassSetListDisplayCondition(listCondition)
    ),
  /** Stateのクリア */
  clearClassSetListState: () =>
    dispatch(classSetListActions.clearClassSetListState()),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(classSetListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'class-set-id',
    title: 'クラスセット ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'class-set',
    title: 'クラスセット',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'name',
    title: 'クラスセット名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'remarks',
    title: '備考',
    width: 300,
    sortable: false,
    position: 'left',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const ClassSetList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()

  useEffect(() => {
    props.getClassSetList()
    return () => {
      props.clearClassSetListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedUserGroupKind,
  } = useTable(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.classSetListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.classSetListDisplayCondition.searchValue])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: ClassSet[] = props.domainData.currentClassSetList.map(
      (classSet: ClassSet) => ({
        classSetId: classSet.classSetId,
        classSet: classSet.classSet,
        classSetName: classSet.classSetName,
        createdAt: classSet.createdAt,
        remarks: classSet.remarks,
      })
    ) as ClassSet[]

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'classSetId':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          case 'createdAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'classSetName':
          case 'remarks':
          case 'classSet':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography align='left'>
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              </Box>
            ) : (
              <Tooltip title={value} placement='bottom'>
                <Typography align='left'>{value}</Typography>
              </Tooltip>
            )
          default:
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [props.domainData.currentClassSetList])

  /** テーブル */
  const classSetListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.classSetListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.classSetListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.classSetListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.classSetListDisplayCondition.sortKey,
          order: props.domainData.classSetListDisplayCondition.sortOrder,
        }}
        includesStatusProgressBar={false}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.classSetListDisplayCondition,
    props.appState.inProgress,
  ])

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  return (
    <>
      <Box className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'クラスセット一覧',
              path: 'class-sets',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex'>
            <ClassSetIcon
              className={classes.pageIcon}
              data-testid='classSetIcon'
            />
            <Typography component='div'>
              <h2 data-testid='class-set-list-title'>クラスセット</h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='class-set-entry' to={`${url}/entry`}>
              <Typography>クラスセット作成</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <FormControl variant='outlined' fullWidth>
            <InputLabel id='classSetListUserGroupKind'>データ種別</InputLabel>
            <Select
              labelId='classSetListUserGroupKind-label'
              id='classSetListUserGroupKind-outlined'
              value={
                props.domainData.classSetListDisplayCondition
                  .selectedUserGroupKind
              }
              onChange={(e) =>
                selectedUserGroupKind(
                  e.target.value as 'UserGroup' | 'SharedUserGroup'
                )
              }
              label='Select User Group Kind'
            >
              {userGroupKindList.map((kind) => (
                <MenuItem
                  data-testid={kind.kind}
                  value={kind.kind}
                  key={kind.kind}
                >
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (クラスセット ID)'
                value={
                  props.domainData.classSetListDisplayCondition.searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {classSetListTable}
        </CustomTrainingPageParagraph>
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const ClassSetListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClassSetList))

const useTable = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    /** 検索ワードを変更 */
    props.setListDisplayCondition({
      ...props.domainData.classSetListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.classSetListDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })

    props.getClassSetList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.classSetListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.classSetListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getClassSetList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.classSetListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getClassSetList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.classSetListDisplayCondition,
      pageNumber: 0,
    })

    props.getClassSetList()
  }

  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearClassSetListState()
    props.setListDisplayCondition({
      ...props.domainData.classSetListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getClassSetList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedUserGroupKind,
  }
}
