import type { AxiosResponse } from 'axios'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { getAxiosInstance } from 'state/utils'
import { CreateDatasetRequestType } from './types'

const timeoutLimitSecondsForValidate = 180
const timeoutLimitSecondsForUpload = 600

export const FileUploadApi = {
  uploadAnnotation: (
    signed_url: string,
    file: File
  ): Promise<AxiosResponse<void>> =>
    getAxiosInstance().upload(signed_url, file, {
      headers: { 'Content-Type': file.type },
    }),
  uploadFile: (
    signed_url: string,
    file: File
  ): Promise<{ fileName: string; hasError: boolean }> =>
    getAxiosInstance()
      .upload(signed_url, file, {
        headers: { 'Content-Type': file.type },
      })
      .then(() => ({ fileName: file.name, hasError: false }))
      .catch(() => ({ fileName: file.name, hasError: true })),
  getSignedUrlData: (
    userGroupId: string,
    fileInfoList: { fileType: string; fileName: string }[]
  ): Promise<
    HttpsCallableResult<{ [fileName: string]: { id: string; url: string } }>
  > =>
    httpsCallable<
      {
        userGroupId: string
        fileInfoList: { fileType: string; fileName: string }[]
      },
      { [fileName: string]: { id: string; url: string } }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-dataset-get-signed-url'
    )({
      userGroupId,
      fileInfoList,
    }),
  validateAnnotation: (
    annotationId: string,
    fileName: string,
    annotationFormatKind: string
  ): Promise<HttpsCallableResult<{ isValid: boolean }>> =>
    httpsCallable<
      {
        annotationId: string
        fileName: string
        annotationFormatKind: string
      },
      { isValid: boolean }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-dataset-validate-annotation',
      {
        timeout: timeoutLimitSecondsForValidate * 1000,
      }
    )({
      annotationId,
      fileName,
      annotationFormatKind,
    }),
  getFileUploadSignedUrl: (
    fileInfoList: {
      fileType: string | undefined
      fileName: string | undefined
    }[]
  ): Promise<
    HttpsCallableResult<{
      [fileName: string]: { url: string; trainingImageId: string }
    }>
  > =>
    httpsCallable<
      {
        fileInfoList: {
          fileType: string | undefined
          fileName: string | undefined
        }[]
      },
      { [fileName: string]: { url: string; trainingImageId: string } }
    >(functionsInstance, AidpCloudFunctionsUrl() + 'file-upload-signed-url', {
      timeout: timeoutLimitSecondsForUpload * 1000,
    })({
      fileInfoList,
    }),
  dataSetFireStore: (
    dataset: CreateDatasetRequestType
  ): Promise<
    HttpsCallableResult<{ datasetId: string; groupedDataId: string }>
  > =>
    httpsCallable<
      { dataset: CreateDatasetRequestType },
      { datasetId: string; groupedDataId: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-dataset-dataset-firestore',
      {
        timeout: timeoutLimitSecondsForUpload * 1000,
      }
    )({
      dataset,
    }),
}
