import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      header: {
        height: number
        borderBottom: string
      }
      sideMenu: {
        width: number
        shrinked: {
          width: number
        }
      }
      table: {
        resultCountSelect: {
          width: number
        }
      }
      scrollBar: {
        width: number
      }
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      header?: {
        height?: number
        borderBottom?: string
      }
      sideMenu?: {
        width?: number
        shrinked?: {
          width?: number
        }
      }
      table?: {
        resultCountSelect?: {
          width?: number
        }
      }
      scrollBar?: {
        width?: number
      }
    }
  }
}
const defaultTheme = createTheme()

const getHeaderBorderStyleByEnv = (): string => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_ID) {
    case 'kcr-aidp-sdb-feature-webapp':
      return '4px yellow solid'
    case 'kcr-aidp-dev':
      if (process.env.REACT_APP_FIREBASE_AUTH_DOMEIN !== undefined) {
        return '4px blue solid'
      } else {
        return '4px green solid'
      }
    case 'kcr-aidp-stg':
      return '4px red solid'
    default:
      return 'none'
  }
}

const getTheme = () =>
  createTheme({
    palette: {
      text: {
        primary: defaultTheme.palette.text.primary,
        secondary: defaultTheme.palette.text.secondary,
      },
      background: {
        default: defaultTheme.palette.background.default,
        paper: defaultTheme.palette.background.paper,
      },
      primary: { main: '#3f51b5' },
      secondary: { main: '#f50057' },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: [
        'Roboto',
        'Noto Sans JP',
        'Lato',
        '游ゴシック Medium',
        '游ゴシック体',
        'Yu Gothic Medium',
        'YuGothic',
        'ヒラギノ角ゴ ProN',
        'Hiragino Kaku Gothic ProN',
        'メイリオ',
        'Meiryo',
        'ＭＳ Ｐゴシック',
        'MS PGothic',
        'sans-serif',
      ].join(','),
    },
    custom: {
      header: {
        height: 64,
        borderBottom: getHeaderBorderStyleByEnv(),
      },
      sideMenu: {
        width: 240,
        shrinked: {
          width: 57,
        },
      },
      table: {
        resultCountSelect: {
          width: 72,
        },
      },
      scrollBar: {
        width: getScrollWidth(),
      },
    },
  })

const getScrollWidth = () => {
  const inner = document.createElement('p')
  inner.style.width = '100px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth
  if (w1 == w2) w2 = outer.clientWidth

  document.body.removeChild(outer)

  return w1 - w2
}

export default getTheme
