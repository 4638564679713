import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

export const CreateClassSetApi = {
  createClassSet: (classSet: {
    classSets: string[]
    metadata: {
      name: string
      remarks: string
    }
  }): Promise<
    HttpsCallableResult<{
      classSetId: string
    }>
  > =>
    httpsCallable<
      {
        classSets: string[]
        metadata: {
          name: string
          remarks: string
        }
      },
      { classSetId: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-classset-firestore',
      {
        timeout: 600 * 1000,
      }
    )({
      ...classSet,
    }),
}
