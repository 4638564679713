import axios from 'axios'
import { Dispatch } from 'redux'
import {
  getAccountSettingCollection,
  getGroupedDataCollection,
  getGroupedDataMetadataCollection,
  getMlPipelineQueriesCollection,
  getTrainedModelQueriesCollection,
  getTrainingDataCollection,
  getTrainingImagesCollection,
} from 'state/firebase'
import { State } from 'state/store'
import { inferenceDetailActions } from './'
import { Log, InferenceResultFile, TrainingData } from './types'
import { InferenceDetailApi } from './apis'
import { convertProgressRateByTransactionStatusForCustomTraining } from 'state/utils'
import {
  isGetLogResponse,
  isObjectRecognitionInferenceResultV1,
  isGetProcessedUrlResponse,
  isGetInferenceResultLogResponse,
} from 'state/utils/typeguard'
import { doc, getDoc, query, getDocs, where } from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataDocument } from 'utils/fireStore/groupedData'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataMetadataDocument } from 'utils/fireStore/groupedDataMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForInferenceMLPipelineQueryDocument } from 'utils/fireStore/inferenceMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingDataDocument } from 'utils/fireStore/trainingData'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingImagesDocument } from 'utils/fireStore/trainingImage'
import saveAs from 'file-saver'
import { domainDataOperations } from 'state/app/domainData/operations'

/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    inferenceDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

const getTrainingData = async (
  userGroupId: string,
  trainingDataIds: string[]
): Promise<TrainingData[]> => {
  try {
    const trainingDataList: (
      | {
          id: string
          originalFileName: string
          fileName: string
        }
      | undefined
    )[] = await Promise.all(
      trainingDataIds.map(async (trainingDataId: string) => {
        const trainingDataDoc = (
          await getDoc(
            doc(getTrainingDataCollection(userGroupId), trainingDataId)
          )
        ).data()

        if (!fireStoreTypeGuardForTrainingDataDocument(trainingDataDoc)) {
          return
        }
        const trainingImageDoc = (
          await getDoc(
            doc(getTrainingImagesCollection(userGroupId), trainingDataId)
          )
        ).data()

        if (!fireStoreTypeGuardForTrainingImagesDocument(trainingImageDoc)) {
          return
        }

        return {
          id: trainingDataId,
          originalFileName: trainingDataDoc ? trainingDataDoc['file-name'] : '',
          fileName: 'thumbnail.jpg',
        }
      })
    )

    const filteredTrainingDataList = trainingDataList.filter(
      (item) => item !== undefined
    ) as { id: string; originalFileName: string; fileName: string }[]

    const hasThumbnailTrainingDataList = filteredTrainingDataList.filter(
      (item) => item.fileName !== ''
    ) as { id: string; originalFileName: string; fileName: string }[]

    // signedUrl取得
    let thumbnailUrls: { [id: string]: string } = {}

    if (hasThumbnailTrainingDataList.length > 0) {
      thumbnailUrls = await InferenceDetailApi.getSignedUrls(
        hasThumbnailTrainingDataList
      )
    }

    return filteredTrainingDataList.map((trainingData) => {
      return {
        id: trainingData.id,
        fileName: trainingData.originalFileName,
        thumbnailUrl: thumbnailUrls[trainingData.id] ?? '',
        processedUrl: '',
      }
    })
  } catch (error) {
    console.error(error)
    return []
  }
}

export const InferenceDetailOperations = {
  /** リストを取得する */
  getInferenceDetail:
    (mlPipelineId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(
          inferenceDetailActions.setInProgressForGettingInferenceDetail(true)
        )
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        /** mlPipelineのデータ */
        const mlPipelineData = (
          await getDoc(
            doc(getMlPipelineQueriesCollection(userGroupId), mlPipelineId)
          )
        ).data()

        if (!mlPipelineData) {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineDataState: 'NotFoundProcessed',
            })
          )
          return
        }

        if (
          !fireStoreTypeGuardForInferenceMLPipelineQueryDocument(mlPipelineData)
        ) {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        const trainedModelUserGroupId =
          mlPipelineData['inference-step']?.['src']?.['trained-model'][
            'user-group-id'
          ] ?? userGroupId

        const trainedModelId =
          mlPipelineData['inference-step']['src']['trained-model'][
            'trained-model-id'
          ]
        /** trained-modelのデータ */
        const trainedModelData = (
          await getDocs(
            userGroupId === trainedModelUserGroupId
              ? query(
                  getTrainedModelQueriesCollection(trainedModelUserGroupId),
                  where('trained-model-id', '==', trainedModelId)
                )
              : query(
                  getTrainedModelQueriesCollection(trainedModelUserGroupId),
                  where('trained-model-id', '==', trainedModelId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
          )
        ).docs[0]?.data()

        const isSharedUserGroupModel =
          trainedModelUserGroupId === sharedUserGroupId

        if (!fireStoreTypeGuardForModelQueryDocument(trainedModelData)) {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }
        /** グループデータのデータ(画像セット) */
        const groupedData = mlPipelineData
          ? (
              await getDoc(
                doc(
                  getGroupedDataCollection(userGroupId),
                  mlPipelineData['inference-step']['src'][
                    'annotation-set-list'
                  ][0]['grouped-data-id']
                )
              )
            ).data()
          : undefined

        if (!fireStoreTypeGuardForGroupedDataDocument(groupedData)) {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }
        const imageListCount: string[] = groupedData
          ? groupedData['training-data-list']
          : []
        /** グループデータのメタデータのデータ(画像セット) */
        const groupedDataMetadata = mlPipelineData
          ? (
              await getDoc(
                doc(
                  getGroupedDataMetadataCollection(userGroupId),
                  mlPipelineData['inference-step']['src'][
                    'annotation-set-list'
                  ][0]['grouped-data-id']
                )
              )
            ).data()
          : undefined

        if (
          !fireStoreTypeGuardForGroupedDataMetadataDocument(groupedDataMetadata)
        ) {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        const algorithmData = getState().app.domainData.algorithms.find(
          (algorithm) =>
            algorithm.algorithmId ===
            mlPipelineData['inference-step']['src']['algorithm-id']
        )

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                mlPipelineData ? mlPipelineData['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        let trainingDataList: TrainingData[] = []
        if (
          groupedData &&
          groupedData['training-data-list'] &&
          mlPipelineData['ml-pipeline']['transaction-status'] === 'Completed'
        ) {
          trainingDataList = await getTrainingData(
            userGroupId,
            groupedData['training-data-list']
          )
        }

        if (trainingDataList.length > 0) {
          dispatch(
            inferenceDetailActions.setSelectedImageId(trainingDataList[0].id)
          )
        }

        dispatch(
          inferenceDetailActions.setCurrentInferenceDetail({
            mlPipelineId: mlPipelineData
              ? mlPipelineData['ml-pipeline']['ml-pipeline-id']
              : '',
            mlPipelineName: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['name']
              : '',
            mlPipelineRemarks: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['remarks']
              : '',
            groupedData: {
              groupedDataId: groupedData ? groupedData['grouped-data-id'] : '',
              groupedDataName: groupedDataMetadata
                ? groupedDataMetadata['name']
                : '',
              datasetId: mlPipelineData
                ? mlPipelineData['inference-step']['src']['dataset-id']
                : '',
              annotationSetId: mlPipelineData
                ? mlPipelineData['inference-step']['src'][
                    'annotation-set-list'
                  ][0]['annotation-set-id']
                : '',
              imageListCount: imageListCount.length,
              trainingDataList: trainingDataList,
            },
            progress: {
              transactionStatus: mlPipelineData
                ? mlPipelineData['ml-pipeline']['transaction-status']
                : '',
              progressRate:
                convertProgressRateByTransactionStatusForCustomTraining(
                  mlPipelineData
                    ? mlPipelineData['ml-pipeline']['transaction-status']
                    : ''
                ),
            },
            startedAt: mlPipelineData
              ? mlPipelineData['ml-pipeline']['started-at']
              : '',
            endedAt:
              mlPipelineData && mlPipelineData['ml-pipeline']['ended-at']
                ? mlPipelineData['ml-pipeline']['ended-at'].seconds === 0
                  ? undefined
                  : mlPipelineData
                  ? mlPipelineData['ml-pipeline']['ended-at']
                  : ''
                : undefined,
            trainedModel: {
              trainedModelId: trainedModelData
                ? trainedModelData['trained-model-id']
                : '',
              trainedModelName: trainedModelData
                ? trainedModelData['trained-model-name']
                : '',
              trainingAlgorithmVersion: {
                displayName: trainedModelData
                  ? trainedModelData['training-algorithm-version'][
                      'display-name'
                    ]
                  : '',
                major: trainedModelData
                  ? trainedModelData['training-algorithm-version']['major']
                  : '',
                minor: trainedModelData
                  ? trainedModelData['training-algorithm-version']['minor']
                  : '',
                patch: trainedModelData
                  ? trainedModelData['training-algorithm-version']['patch']
                  : '',
                preRelease: trainedModelData
                  ? trainedModelData['training-algorithm-version'][
                      'pre-release'
                    ]
                  : '',
              },
              isSharedUserGroupModel: isSharedUserGroupModel,
            },
            inferenceAlgorithm: {
              algorithmName: algorithmData?.metadata.name.ja ?? '',
              inferenceAlgorithmVersion: {
                displayName:
                  mlPipelineData['inference-step']['src'][
                    'inference-algorithm-version'
                  ]['display-name'],
                major:
                  mlPipelineData['inference-step']['src'][
                    'inference-algorithm-version'
                  ]['major'],
                minor:
                  mlPipelineData['inference-step']['src'][
                    'inference-algorithm-version'
                  ]['minor'],
                patch:
                  mlPipelineData['inference-step']['src'][
                    'inference-algorithm-version'
                  ]['patch'],
              },
            },
            createdBy: accountSetting
              ? {
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : mlPipelineData
              ? mlPipelineData['created-by']
              : '',
          })
        )
        dispatch(
          inferenceDetailActions.setInferenceDetailState({
            ...getState().pages.inferenceDetailState.appState
              .inferenceDetailState,
            mlPipelineDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          inferenceDetailActions.setInferenceDetailState({
            ...getState().pages.inferenceDetailState.appState
              .inferenceDetailState,
            mlPipelineDataState: 'Failed',
          })
        )
      } finally {
        dispatch(
          inferenceDetailActions.setInProgressForGettingInferenceDetail(false)
        )
      }
    },
  /** 推論結果の取得 */
  getResult:
    (mlPipelineId: string, trainingDataId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(
          inferenceDetailActions.setInferenceDetailState({
            ...getState().pages.inferenceDetailState.appState
              .inferenceDetailState,
            inferenceResultSubState: 'BeforeLoading',
          })
        )
        const response = await InferenceDetailApi.getResult(
          mlPipelineId,
          trainingDataId
        )

        const condition =
          getState().pages.inferenceDetailState.domainData
            .inferenceResultDisplayCondition

        if (isObjectRecognitionInferenceResultV1(response)) {
          const labelGroupIds: string[] = []
          response.data.results.forEach((res) => {
            const index = labelGroupIds.findIndex(
              (labelGroupId) => labelGroupId === res.label.id
            )
            if (index === -1) {
              labelGroupIds.push(res.label.id)
            }
          })

          dispatch(
            inferenceDetailActions.setInferenceResultDisplayCondition({
              ...condition,
              selectedLabelIds: labelGroupIds,
            })
          )

          dispatch(
            inferenceDetailActions.setCurrentInferenceResults([
              ...response.data.results,
            ])
          )

          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              inferenceResultSubState: 'Loaded',
            })
          )
        }
      } catch {
        dispatch(
          inferenceDetailActions.setInferenceDetailState({
            ...getState().pages.inferenceDetailState.appState
              .inferenceDetailState,
            inferenceResultSubState: 'Failed',
          })
        )
      } finally {
        dispatch(inferenceDetailActions.setIsInProgressForRendering(false))
      }
    },
  /** ファイルデータの取得 */
  getFileData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const requestMlPipelineId =
          getState().pages.inferenceDetailState.domainData
            .currentInferenceDetail?.mlPipelineId
        dispatch(
          inferenceDetailActions.setInProgressForGettingInferenceResult(true)
        )
        let mlPipelineLogItems: Log[] = []
        let resultLogItems: InferenceResultFile[] = []
        // リザルトファイル用キャッチ
        try {
          const logResponse = await InferenceDetailApi.getResultLogs(
            requestMlPipelineId ? requestMlPipelineId : ''
          )
          if (isGetInferenceResultLogResponse(logResponse)) {
            resultLogItems = logResponse.data.items.map((log) => ({
              fileName: log.name,
              fileLink: log.fileNameForDownload,
              fileSize: log.size,
              createdAt: new Date(log.createdAt),
            }))

            dispatch(
              inferenceDetailActions.setInferenceDetailState({
                ...getState().pages.inferenceDetailState.appState
                  .inferenceDetailState,
                inferenceResultDlLinkSubState: 'Loaded',
              })
            )
          }
        } catch {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              inferenceResultDlLinkSubState: 'Failed',
            })
          )
        }

        // ログファイル用キャッチ
        try {
          const logResponse = await InferenceDetailApi.getLogs(
            requestMlPipelineId ? requestMlPipelineId : ''
          )
          if (isGetLogResponse(logResponse)) {
            mlPipelineLogItems = logResponse.data.payload.customJobs.map(
              (customJob) => ({
                pipelineJobId: logResponse.data.payload.pipelineJobId,
                customJobId: customJob.customJobId,
                displayName: customJob.displayName,
              })
            )

            dispatch(
              inferenceDetailActions.setInferenceDetailState({
                ...getState().pages.inferenceDetailState.appState
                  .inferenceDetailState,
                mlPipelineLogSubState: 'Loaded',
              })
            )
          }
        } catch {
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              mlPipelineLogSubState: 'Failed',
            })
          )
        }

        const currentMlPipelineId =
          getState().pages.inferenceDetailState.domainData
            .currentInferenceDetail?.mlPipelineId
        if (requestMlPipelineId === currentMlPipelineId) {
          dispatch(inferenceDetailActions.setResultFiles(resultLogItems))
          dispatch(
            inferenceDetailActions.setMlPipelineLogFiles(mlPipelineLogItems)
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(
          inferenceDetailActions.setInProgressForGettingInferenceResult(false)
        )
      }
    },
  /** 加工画像urlの取得 */
  getProcessedUrl:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(inferenceDetailActions.setIsInProgressForRendering(true))
        dispatch(
          inferenceDetailActions.setInferenceDetailState({
            ...getState().pages.inferenceDetailState.appState
              .inferenceDetailState,
            processedTrainingDataUrlSubState: 'BeforeLoading',
          })
        )
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const mlPipelineId =
          getState().pages.inferenceDetailState.domainData
            .currentInferenceDetail?.mlPipelineId

        const trainingDataList =
          getState().pages.inferenceDetailState.domainData
            .currentInferenceDetail?.groupedData.trainingDataList

        const selectedTrainingDataId =
          getState().pages.inferenceDetailState.domainData
            .selectedTrainingDataId

        const selectedTrainingData = trainingDataList?.find(
          (trainingData) => trainingData.id === selectedTrainingDataId
        )

        if (
          !selectedTrainingData ||
          !mlPipelineId ||
          selectedTrainingData.processedUrl ||
          !trainingDataList
        ) {
          return
        }

        const response = await InferenceDetailApi.getProcessedSignedUrl(
          userGroupId,
          mlPipelineId,
          selectedTrainingDataId,
          selectedTrainingData.fileName
        )
        const currentInferenceDetail =
          getState().pages.inferenceDetailState.domainData
            .currentInferenceDetail
        if (isGetProcessedUrlResponse(response) && currentInferenceDetail) {
          dispatch(
            inferenceDetailActions.setCurrentInferenceDetail({
              ...currentInferenceDetail,
              groupedData: {
                ...currentInferenceDetail.groupedData,
                trainingDataList: trainingDataList.map((trainingData) => {
                  return {
                    ...trainingData,
                    processedUrl:
                      trainingData.id === selectedTrainingDataId
                        ? response.data.url
                        : trainingData.processedUrl,
                  }
                }),
              },
            })
          )
          dispatch(
            inferenceDetailActions.setInferenceDetailState({
              ...getState().pages.inferenceDetailState.appState
                .inferenceDetailState,
              processedTrainingDataUrlSubState: 'Loaded',
            })
          )
        }
      } catch (error) {
        dispatch(inferenceDetailActions.setIsInProgressForRendering(false))
        dispatch(
          inferenceDetailActions.setInferenceDetailState({
            ...getState().pages.inferenceDetailState.appState
              .inferenceDetailState,
            processedTrainingDataUrlSubState: 'Failed',
          })
        )
      }
    },
  /** 推論結果ファイルのダウンロード */
  resultFilesDownload:
    (link: InferenceResultFile) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(inferenceDetailActions.setInProgressForDownloading(true))
        // ファイルDL失敗リスト
        const failedModelFileList: string[] = []
        try {
          await axios
            .get(link.fileLink, {
              responseType: 'blob',
            })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: response.data.type,
              })
              saveAs(blob, link.fileName)
            })
        } catch {
          failedModelFileList.push(link.fileName)
        }
        // 失敗した画像があれば、Storeに保持
        if (failedModelFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedModelFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(inferenceDetailActions.setInProgressForDownloading(false))
      }
    },
  /** ログファイルのダウンロード */
  logFileDownload:
    (logFiles: Log[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(inferenceDetailActions.setInProgressForDownloading(true))
        // DL失敗の画像のリスト
        const failedLogFileList: string[] = []

        await Promise.all(
          logFiles.map(async (logFile) => {
            try {
              const res = await InferenceDetailApi.downloadLog(
                logFile.pipelineJobId,
                logFile.customJobId
              )
              const blob = new Blob([res.data.blob], {
                type: res.data.type,
              })

              const fileName = `${logFile.displayName.replaceAll(' ', '_')}.log`

              saveAs(blob, fileName)
            } catch {
              failedLogFileList.push(logFile.displayName)
            }
          })
        )

        // 失敗した画像があれば、Storeに保持
        if (failedLogFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedLogFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(inferenceDetailActions.setInProgressForDownloading(false))
      }
    },
}
