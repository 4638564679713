import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  ClassSet,
  ClassSetListActionType,
  ClassSetListDisplayCondition,
} from './types'

export type ClassSetListAction = ActionsUnion<typeof classSetListActions>

export const classSetListActions = {
  setList: (
    currentClassSetList: ClassSet[]
  ): ActionWithPayload<
    'SET_CLASS_SET_LIST_FOR_CLASS_SET_LIST',
    { currentClassSetList: ClassSet[] }
  > =>
    CreateAction(ClassSetListActionType.SET_CLASS_SET_LIST_FOR_CLASS_SET_LIST, {
      currentClassSetList,
    }),
  setClassSetListDisplayCondition: (
    classSetDisplayCondition?: ClassSetListDisplayCondition
  ): ActionWithPayload<
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST',
    {
      classSetDisplayCondition?: ClassSetListDisplayCondition
    }
  > =>
    CreateAction(
      ClassSetListActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST,
      {
        classSetDisplayCondition,
      }
    ),
  clearClassSetListDisplayCondition:
    (): Action<'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST'> =>
      CreateAction(
        ClassSetListActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CLASS_SET_LIST
      ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_CLASS_SET_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(ClassSetListActionType.SET_IN_PROGRESS_FOR_CLASS_SET_LIST, {
      inProgress,
    }),
  setClassSetIdList: (
    classSetIds: string[]
  ): ActionWithPayload<
    'SET_CLASS_SET_IDS_FOR_CLASS_SET_LIST',
    { classSetIds: string[] }
  > =>
    CreateAction(ClassSetListActionType.SET_CLASS_SET_IDS_FOR_CLASS_SET_LIST, {
      classSetIds,
    }),
  setCurrentClassSetListSnapshot: (
    currentClassSetListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_CLASS_SET_LIST_SNAPSHOT_FOR_CLASS_SET_LIST',
    { currentClassSetListSnapshot?: () => void }
  > =>
    CreateAction(
      ClassSetListActionType.SET_CURRENT_CLASS_SET_LIST_SNAPSHOT_FOR_CLASS_SET_LIST,
      {
        currentClassSetListSnapshot,
      }
    ),
  clearClassSetListState: (): Action<'CLEAR_CLASS_SET_LIST_STATE'> =>
    CreateAction(ClassSetListActionType.CLEAR_CLASS_SET_LIST_STATE),
}
