export const ClassSetEntryActionType = {
  SET_CLASS_SET_FOR_CLASS_SET_ENTRY: 'SET_CLASS_SET_FOR_CLASS_SET_ENTRY',
  SET_CLASS_SET_NAME_FOR_CLASS_SET_ENTRY:
    'SET_CLASS_SET_NAME_FOR_CLASS_SET_ENTRY',
  SET_CLASS_SET_REMARKS_FOR_CLASS_SET_ENTRY:
    'SET_CLASS_SET_REMARKS_FOR_CLASS_SET_ENTRY',
  SET_CLASS_SET_STATE_FOR_CLASS_SET_ENTRY:
    'SET_CLASS_SET_STATE_FOR_CLASS_SET_ENTRY',
  SET_IN_PROGRESS_FOR_CLASS_SET_ENTRY: 'SET_IN_PROGRESS_FOR_CLASS_SET_ENTRY',
  SET_METADATA_SUB_STATE_FOR_CLASS_SET_ENTRY:
    'SET_METADATA_SUB_STATE_FOR_CLASS_SET_ENTRY',
  SET_EXECUTE_SUB_STATE_FOR_CLASS_SET_ENTRY:
    'SET_EXECUTE_SUB_STATE_FOR_CLASS_SET_ENTRY',
  SET_CLASS_SET_ID_FOR_CLASS_SET_ENTRY: 'SET_CLASS_SET_ID_FOR_CLASS_SET_ENTRY',
  CLEAR_CLASS_SET_ENTRY_STATE: 'CLEAR_CLASS_SET_ENTRY_STATE',
} as const

export type ExecuteType =
  | 'BeforeExecute'
  | 'ExecuteInProgress'
  | 'Executed'
  | 'ExecuteError'

export type Metadata = {
  name: string
  remarks: string
}

export interface ClassSetEntryDomainData {
  classSets: string[]
  metadata: Metadata
  classSetId?: string
}

export const ClassSetEntryStateKindArray = [
  'ClassSetState',
  'MetadataState',
  'ExecuteState',
]

export type ClassSetEntryStateKind =
  (typeof ClassSetEntryStateKindArray)[number]

export interface ClassSetEntrySubState {
  metadataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState: ExecuteType
}

export interface ClassSetEntryAppState {
  classSetEntryState: ClassSetEntryStateKind
  classSetEntrySubState: ClassSetEntrySubState
  inProgress: boolean
}

export interface CurrentClassSetEntryState {
  domainData: ClassSetEntryDomainData
  appState: ClassSetEntryAppState
}
