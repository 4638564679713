import { InferenceResultV1 } from 'state/utils'
import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  InferenceDetailActionType,
  CurrentInferenceDetail,
  InferenceDetailState,
  ToastInfo,
  InferenceResultFile,
  Log,
  InferenceResultDisplayCondition,
} from './types'

export type InferenceDetailAction = ActionsUnion<typeof inferenceDetailActions>

export const inferenceDetailActions = {
  setCurrentInferenceDetail: (
    currentInferenceDetail: CurrentInferenceDetail
  ): ActionWithPayload<
    'SET_CURRENT_INFERENCE_DETAIL',
    { currentInferenceDetail: CurrentInferenceDetail }
  > =>
    CreateAction(InferenceDetailActionType.SET_CURRENT_INFERENCE_DETAIL, {
      currentInferenceDetail,
    }),
  setCurrentInferenceResults: (
    currentInferenceResults: InferenceResultV1[]
  ): ActionWithPayload<
    'SET_CURRENT_INFERENCE_RESULTS',
    { currentInferenceResults: InferenceResultV1[] }
  > =>
    CreateAction(InferenceDetailActionType.SET_CURRENT_INFERENCE_RESULTS, {
      currentInferenceResults,
    }),
  setInferenceDetailState: (
    inferenceDetailState: InferenceDetailState
  ): ActionWithPayload<
    'SET_INFERENCE_DETAIL_STATE',
    { inferenceDetailState: InferenceDetailState }
  > =>
    CreateAction(InferenceDetailActionType.SET_INFERENCE_DETAIL_STATE, {
      inferenceDetailState,
    }),
  setInProgressForGettingInferenceDetail: (
    isInProgressForGettingInferenceDetail: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_INFERENCE_DETAIL',
    { isInProgressForGettingInferenceDetail: boolean }
  > =>
    CreateAction(
      InferenceDetailActionType.SET_IN_PROGRESS_FOR_GETTING_INFERENCE_DETAIL,
      {
        isInProgressForGettingInferenceDetail,
      }
    ),
  setInProgressForGettingInferenceResult: (
    isInProgressForGettingInferenceResult: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_INFERENCE_RESULT',
    { isInProgressForGettingInferenceResult: boolean }
  > =>
    CreateAction(
      InferenceDetailActionType.SET_IN_PROGRESS_FOR_GETTING_INFERENCE_RESULT,
      {
        isInProgressForGettingInferenceResult,
      }
    ),
  setInProgressForDownloading: (
    isInProgressForDownloading: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DOWNLOADING',
    { isInProgressForDownloading: boolean }
  > =>
    CreateAction(InferenceDetailActionType.SET_IN_PROGRESS_FOR_DOWNLOADING, {
      isInProgressForDownloading,
    }),
  setIsInProgressForRendering: (
    isInProgressForRendering: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_RENDERING',
    { isInProgressForRendering: boolean }
  > =>
    CreateAction(InferenceDetailActionType.SET_IN_PROGRESS_FOR_RENDERING, {
      isInProgressForRendering,
    }),
  setSelectedImageId: (
    imageId: string
  ): ActionWithPayload<'SET_SELECTED_IMAGE_ID', { imageId: string }> =>
    CreateAction(InferenceDetailActionType.SET_SELECTED_IMAGE_ID, {
      imageId,
    }),
  clearInferenceDetailState: (): Action<'CLEAR_INFERENCE_DETAIL_STATE'> =>
    CreateAction(InferenceDetailActionType.CLEAR_INFERENCE_DETAIL_STATE),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_INFERENCE_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      InferenceDetailActionType.SET_TOAST_INFO_FOR_INFERENCE_DETAIL,
      {
        toastInfo,
      }
    ),
  setResultFiles: (
    resultLogFiles: InferenceResultFile[]
  ): ActionWithPayload<
    'SET_RESULT_FILES',
    { resultLogFiles: InferenceResultFile[] }
  > =>
    CreateAction(InferenceDetailActionType.SET_RESULT_FILES, {
      resultLogFiles,
    }),
  setMlPipelineLogFiles: (
    mlPipelineLogFiles: Log[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LOG_FILES',
    { mlPipelineLogFiles: Log[] }
  > =>
    CreateAction(InferenceDetailActionType.SET_ML_PIPELINE_LOG_FILES, {
      mlPipelineLogFiles,
    }),
  setInferenceResultDisplayCondition: (
    displayCondition: InferenceResultDisplayCondition
  ): ActionWithPayload<
    'SET_INFERENCE_RESULT_DISPLAY_CONDITION',
    { displayCondition: InferenceResultDisplayCondition }
  > =>
    CreateAction(
      InferenceDetailActionType.SET_INFERENCE_RESULT_DISPLAY_CONDITION,
      {
        displayCondition,
      }
    ),
}
