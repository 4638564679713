import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  ClassSetEntryActionType,
  ClassSetEntryStateKind,
  ExecuteType,
} from './types'

export type ClassSetEntryAction = ActionsUnion<typeof classSetEntryActions>

export const classSetEntryActions = {
  setClassSet: (
    classSet: string[]
  ): ActionWithPayload<
    'SET_CLASS_SET_FOR_CLASS_SET_ENTRY',
    { classSet: string[] }
  > =>
    CreateAction(ClassSetEntryActionType.SET_CLASS_SET_FOR_CLASS_SET_ENTRY, {
      classSet,
    }),
  setClassSetName: (
    name: string
  ): ActionWithPayload<
    'SET_CLASS_SET_NAME_FOR_CLASS_SET_ENTRY',
    { name: string }
  > =>
    CreateAction(
      ClassSetEntryActionType.SET_CLASS_SET_NAME_FOR_CLASS_SET_ENTRY,
      {
        name,
      }
    ),
  setClassSetRemarks: (
    remarks: string
  ): ActionWithPayload<
    'SET_CLASS_SET_REMARKS_FOR_CLASS_SET_ENTRY',
    { remarks: string }
  > =>
    CreateAction(
      ClassSetEntryActionType.SET_CLASS_SET_REMARKS_FOR_CLASS_SET_ENTRY,
      {
        remarks,
      }
    ),
  setClassSetState: (
    classSetState: ClassSetEntryStateKind
  ): ActionWithPayload<
    'SET_CLASS_SET_STATE_FOR_CLASS_SET_ENTRY',
    { classSetState: ClassSetEntryStateKind }
  > =>
    CreateAction(
      ClassSetEntryActionType.SET_CLASS_SET_STATE_FOR_CLASS_SET_ENTRY,
      {
        classSetState,
      }
    ),
  setMetadataSubState: (
    metadataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_METADATA_SUB_STATE_FOR_CLASS_SET_ENTRY',
    { metadataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(
      ClassSetEntryActionType.SET_METADATA_SUB_STATE_FOR_CLASS_SET_ENTRY,
      {
        metadataSubState,
      }
    ),
  setExecuteSubState: (
    executeSubState: ExecuteType
  ): ActionWithPayload<
    'SET_EXECUTE_SUB_STATE_FOR_CLASS_SET_ENTRY',
    { executeSubState: ExecuteType }
  > =>
    CreateAction(
      ClassSetEntryActionType.SET_EXECUTE_SUB_STATE_FOR_CLASS_SET_ENTRY,
      {
        executeSubState,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_CLASS_SET_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(ClassSetEntryActionType.SET_IN_PROGRESS_FOR_CLASS_SET_ENTRY, {
      inProgress,
    }),
  setClassSetId: (
    classSetId?: string
  ): ActionWithPayload<
    'SET_CLASS_SET_ID_FOR_CLASS_SET_ENTRY',
    { classSetId?: string }
  > =>
    CreateAction(ClassSetEntryActionType.SET_CLASS_SET_ID_FOR_CLASS_SET_ENTRY, {
      classSetId,
    }),
  clearClassSetEntryState: (): Action<'CLEAR_CLASS_SET_ENTRY_STATE'> =>
    CreateAction(ClassSetEntryActionType.CLEAR_CLASS_SET_ENTRY_STATE),
}
