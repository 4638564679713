import React from 'react'

import CategoryIcon from '@mui/icons-material/Category'
import IconButton from '@mui/material/IconButton'

import { ClassSetIconProps } from './types'

export const ClassSetIcon: React.FC<ClassSetIconProps> = (props) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <CategoryIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
