import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import Box from '@mui/material/Box'
import { State } from 'state/store'
import {
  RobotDataAnalysisExtractedImagesActions,
  robotDataAnalysisExtractedImagesActions,
  robotDataAnalysisExtractedImagesOperations,
  RobotDataAnalysisExtractedImagesDisplayCondition,
  QueryConditions,
  ExecutionDataItem,
} from 'state/ducks/robotDataAnalysisExtractedImages'
import {
  SelectableTable,
  CustomTrainingPageParagraph,
  GlobalLoading,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  ErrorMessage,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
  BreadcrumbsComponent,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { Chip, Divider, Link as MuiLink } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CheckCircleOutline, ErrorOutline, Check } from '@mui/icons-material'
import { RobotDataAnalysisIcon } from 'views/components/atoms/icon/robotDataAnalysisIcon'

// 文字列で渡ってきた 'true' , 'false' を boolean に変換
const parseBoolean = (value: string) =>
  value == 'true' ? true : value == 'false' ? false : value

// ケバブケースをスネークケースに変換
const toCamelCase = (key: string) =>
  key
    .split('-')
    .map((char, i) =>
      i === 0
        ? char.toLowerCase()
        : char.charAt(0).toUpperCase() + char.slice(1).toLowerCase()
    )
    .join('')

// 配列型のプロパティ名と一致するかチェック
const isArrayKey = (key: string) =>
  [
    'execution-status',
    'model-group-id-list',
    'execution-id-list',
    'robot-id-list',
  ].includes(key)

const mapStateToProps = (state: State) => ({
  ...state.pages.robotDataAnalysisExtractedImagesState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<
  State,
  void,
  RobotDataAnalysisExtractedImagesActions
>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** ロボット画像データ一覧取得 */
  getExecutionDataList: (condition: QueryConditions) =>
    dispatch(
      robotDataAnalysisExtractedImagesOperations.getExecutionDataList(condition)
    ),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (
    listCondition: RobotDataAnalysisExtractedImagesDisplayCondition
  ) =>
    dispatch(
      robotDataAnalysisExtractedImagesActions.setListDisplayCondition(
        listCondition
      )
    ),
  /** Stateのクリア */
  clearRobotDataAnalysisExtractedImagesState: () =>
    dispatch(
      robotDataAnalysisExtractedImagesActions.clearRobotDataAnalysisExtractedImagesState()
    ),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  head: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'confirmed',
    title: 'Confirmed',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'dataName',
    title: 'Data Name',
    width: 250,
    sortable: true,
    position: 'left',
  },
  {
    id: 'executionStatus',
    title: 'Execution Status',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'executedAt',
    title: 'Executed At',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'modelGroups',
    title: 'Model Groups',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'executionId',
    title: 'Execution ID',
    width: 150,
    sortable: true,
    position: 'left',
  },
  {
    id: 'robotId',
    title: 'Robot ID',
    width: 120,
    sortable: true,
    position: 'left',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const RobotDataAnalysisExtractedImages: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  // クエリーパラメータから検索条件を取得
  const keys = Array.from(new Set(query.keys()))
  // 検索条件
  const [condition, setCondition] = useState<QueryConditions | undefined>()

  const handleClickLink = (trainingDataId: string) => {
    history.push({
      pathname: `/robot-data-analysis/extracted-images/${trainingDataId}`,
    })
  }

  // 検索条件をクエリパラメータから生成してstateに保持
  useEffect(() => {
    // クエリパラメータの存在チェック
    if (keys.length === 0) {
      setCondition(undefined)
      return
    }
    let params = {
      executionStatus: [],
      modelGroupIdList: [],
      executionIdList: [],
      robotIdList: [],
      confirmed: false,
      objectRecognitionResults: true,
    }
    for (const key of keys) {
      const values = query.getAll(key)
      if (values.length === 0 || values[0] === '') {
        continue
      }
      params = {
        ...params,
        [toCamelCase(key)]: isArrayKey(key)
          ? values[0].split(',')
          : parseBoolean(values[0]),
      }
    }
    setCondition(params)
  }, [])

  useEffect(() => {
    // 検索条件がない場合は実行しない
    if (condition) {
      props.getExecutionDataList(condition)
    }
  }, [props.auth.customClaims.userGroupId, condition])

  const { changeTableSortOrder, pageChange, handleChangeDisplayNumber } =
    useTable(props)

  const tableHeader = TABLE_HEADERS

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    let displayList = props.domainData.executionDataList
    // 表示条件
    const condition =
      props.domainData.robotDataAnalysisExtractedImagesDisplayCondition

    // リストのソート
    displayList = displayList.sort((item, item2) => {
      if (item[condition.sortKey] < item2[condition.sortKey]) {
        return props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
          .sortOrder === 'asc'
          ? -1
          : 1
      }
      if (item[condition.sortKey] > item2[condition.sortKey]) {
        return props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
          .sortOrder === 'asc'
          ? 1
          : -1
      }
      return 0
    })

    // 表示条件に合わせて配列を加工
    displayList = props.domainData.executionDataList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.executionDataList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.robotDataAnalysisExtractedImagesDisplayCondition,
    props.domainData.executionDataList,
  ])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    return tableContent.map((data) =>
      Object.entries(data)
        .filter(([key]) => key !== 'isSupportedAlgorithm')
        .map(([key]) => {
          switch (key) {
            case 'confirmed':
              return data[key] !== 'unConfirmed' ? (
                <Check
                  sx={{
                    color: (theme) =>
                      data[key] === 'selfConfirmed'
                        ? theme.palette.success.main
                        : theme.palette.primary.main,
                  }}
                />
              ) : undefined
            case 'trainingData':
              return data[key] ? (
                data.isSupportedAlgorithm ? (
                  <MuiLink
                    className={classes.link}
                    underline='none'
                    onClick={async () => handleClickLink(data[key].id)}
                    data-testid={`file-${data[key].id}`}
                  >
                    <Typography>{data[key].name}</Typography>
                  </MuiLink>
                ) : (
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    {data[key].name}
                  </Typography>
                )
              ) : (
                <Typography
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              )
            case 'executionStatus':
              if (data[key] === 'success') {
                return <CheckCircleOutline color='success' />
              } else if (data[key] === 'fail') {
                return <ErrorOutline color='warning' />
              } else if (data[key] === 'error') {
                return <ErrorOutline color='error' />
              } else {
                return (
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    {TABLE_CELL_NOT_APPLICABLE}
                  </Typography>
                )
              }
            case 'executedAt':
              return data[key] ? (
                <Typography>
                  {formatDateTimeSec(new Date(data[key]))}
                </Typography>
              ) : (
                <Typography
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              )
            case 'modelGroups':
              return data[key] ? (
                <Box sx={{ display: 'flex', overflowX: 'scroll' }}>
                  {data[key].map((item) => (
                    <Box key={item.modelGroupId} mr={0.5}>
                      <Chip label={item.modelGroupName} />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography
                  align='center'
                  sx={{ color: globalTheme.palette.text.secondary }}
                >
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              )
            case 'robotId':
            case 'executionId':
              return (
                <Tooltip title={data[key]} placement='bottom'>
                  <Typography>{data[key].substring(0, 8)}</Typography>
                </Tooltip>
              )
            default:
              return (
                <Typography
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              )
          }
        })
    )
  }, [tableContent, props.domainData.executionDataList])

  /** テーブル */
  const RobotDataAnalysisExtractedImagesTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
            .displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={
          props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
            .totalCount
        }
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={
          props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
            .pageNumber
        }
        sortOrder={{
          key: props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
            .sortKey,
          order:
            props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
              .sortOrder,
        }}
        displayNoneRadio
        includesStatusProgressBar
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.robotDataAnalysisExtractedImagesDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <Box className={classes.container}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'On-site Data',
              path: 'robot-data-analysis/entry',
            },
            {
              name: 'Selected List',
              path: 'robot-data-analysis/extracted-images',
            },
          ]}
        />
        <Box display='flex'>
          <RobotDataAnalysisIcon
            className={classes.pageIcon}
            data-testid='RobotDataAnalysisExtractedImagesTitleIcon'
          />
          <Typography component='div'>
            <h2 data-testid='robot-data-analysis-extracted-images-title'>
              Selected List
            </h2>
          </Typography>
        </Box>
        <Divider />
        <CustomTrainingPageParagraph>
          {keys.length === 0 && (
            <ErrorMessage
              title=''
              targets={['検索条件がありません。再度検索して下さい']}
            />
          )}
          {RobotDataAnalysisExtractedImagesTable}
        </CustomTrainingPageParagraph>
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const useTable = (props: Props) => {
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.robotDataAnalysisExtractedImagesDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.robotDataAnalysisExtractedImagesDisplayCondition,
      sortKey: key as keyof ExecutionDataItem,
      sortOrder:
        props.domainData.robotDataAnalysisExtractedImagesDisplayCondition
          .sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.robotDataAnalysisExtractedImagesDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
  }
}

export const RobotDataAnalysisExtractedImagesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotDataAnalysisExtractedImages))
