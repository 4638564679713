import React from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { makeStyles } from 'tss-react/mui'
import { AddableTextFieldsProps } from './types'
import clsx from 'clsx'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  textFieldContainer: {
    display: 'flex',
  },
  textField: {
    margin: 'normal',
    width: 'calc(100% - 56px)',
  },
  clearIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
  },
  clearIconMarginBottom: {
    marginBottom: 23, // エラーメッセージのhelperText分
  },
  addButton: {
    width: 'calc(100% - 56px)',
    height: 53, // TextFieldの高さ56px - ボーダー1.5px * 2
    border: 'dashed',
    borderColor: theme.palette.grey[500],
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[500],
    cursor: 'pointer',
    ':hover': {
      borderColor: theme.palette.grey[800],
      color: theme.palette.grey[800],
    },
  },
}))

export const AddableTextFields = (props: AddableTextFieldsProps) => {
  const { items, addButton, onChange, onClickClearButton, errorMessages } =
    props
  const { classes } = useStyles()
  return (
    <Box className={classes.container}>
      {items.map((item, index) => (
        <Box
          key={`addable-text-field-${index}`}
          className={classes.textFieldContainer}
        >
          <TextField
            onChange={(e) => onChange(e.target.value, index)}
            value={item}
            error={!!errorMessages?.at(index)}
            helperText={errorMessages?.at(index)}
            type='text'
            className={classes.textField}
          />
          <Box className={classes.clearIconContainer}>
            <IconButton
              onClick={() => onClickClearButton(index)}
              className={clsx({
                [classes.clearIconMarginBottom]: !!errorMessages?.at(index),
              })}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      ))}

      <Box
        className={classes.addButton}
        onClick={addButton.onClick}
      >{`+ ${addButton.label}`}</Box>
    </Box>
  )
}
