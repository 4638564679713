import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'
import { CustomerChangeOperations } from 'state/ducks/customerChange'

import {
  SelectableTableHeader,
  SelectableTable,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  SearchInput,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import {
  Customer,
  CustomerListDisplayCondition,
} from 'utils/ducks/customerChange/types'
import { isUndefined } from 'utils/typeguard'

const mapStateToProps = (state: State) => ({
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dispatch = ThunkDispatch<State, void, any>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カスタマー変更 */
  changeCustomer: (customerId: string) =>
    dispatch(CustomerChangeOperations.changeCustomer(customerId)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps &
  DispatchProps &
  RouteComponentProps & {
    handleCloseDialog: () => void
  }

const useStyles = makeStyles()((theme) => ({
  dialogStyle: {
    width: '900px',
  },
  openButton: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: '#D9E5FF',
    '&:hover': {
      backgroundColor: '#D9E5FF',
    },
  },
  openButtonDisable: {
    opacity: 0.5,
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  cancelButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
  contentBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** カスタマーテーブルのヘッダー */
const CUSTOMER_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'customerName',
    title: 'カスタマー名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'customerId',
    title: 'カスタマーID',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'role',
    title: 'ロール',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'lastAccessed',
    title: '最終アクセス日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
]

const CustomerChangeDialog: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** 選択中のテーブルの行 */
  const [selectRowIndex, setSelectRowIndex] = useState<number | undefined>(
    undefined
  )

  const {
    changeTableSortOrder,
    pageChange,
    searchValue,
    handleChangeSearchValue,
    handleChangeDisplayNumber,
    searchFromSearchValue,
    tableRows,
    displayCondition,
  } = useTableDisplayActions(props)

  const customerTableHeader = CUSTOMER_TABLE_HEADERS

  /** テーブル */
  const customerListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={displayCondition.displayNumber}
        headers={customerTableHeader}
        rows={tableRows}
        totalCount={tableRows.length}
        tableHeight={
          2 + TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT
        }
        fixedColumnNumber={0}
        selectedRowNumber={selectRowIndex}
        page={displayCondition.pageNumber}
        sortOrder={{
          key: displayCondition.sortKey,
          order: displayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
        onClickRow={(row: number) => {
          setSelectRowIndex(row)
        }}
        isPointer={true}
      />
    )
  }, [tableRows, selectRowIndex, displayCondition])

  return (
    <>
      <Dialog
        maxWidth='lg'
        onClose={() => props.handleCloseDialog()}
        open={true}
      >
        <Box m={2} className={classes.dialogStyle}>
          <DialogContent>
            <SearchInput
              placeholder='キーワード (カスタマー名)'
              value={searchValue}
              onChangeValue={(event) =>
                handleChangeSearchValue(event.target.value)
              }
              onClickSearch={() => searchFromSearchValue()}
              onPressEnter={() => searchFromSearchValue()}
            />
            <Box textAlign='center'>{customerListTable}</Box>
          </DialogContent>
          <Box className={classes.contentBtn}>
            <Button
              className={classes.cancelButton}
              onClick={() => props.handleCloseDialog()}
              color={'inherit'}
            >
              キャンセル
            </Button>
            <Button
              disabled={isUndefined(selectRowIndex)}
              className={
                isUndefined(selectRowIndex)
                  ? classes.openButtonDisable
                  : classes.openButton
              }
              variant='outlined'
              onClick={() => {
                if (!isUndefined(selectRowIndex)) {
                  props.changeCustomer(
                    props.customers[selectRowIndex].customerId
                  )
                  const newUrl = getChangeUrl(
                    history.location.pathname,
                    props.customers[selectRowIndex].customerId
                  )
                  history.push(newUrl)
                }
                props.handleCloseDialog()
              }}
            >
              <Typography
                color={isUndefined(selectRowIndex) ? 'textPrimary' : 'primary'}
              >
                開く
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const useTableDisplayActions = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** カスタマー名の検索文字列 */
  const [searchValue, setSearchValue] = useState<string>('')

  /** テーブルの表示条件 */
  const [displayCondition, setDisplayCondition] =
    useState<CustomerListDisplayCondition>({
      searchValue: '',
      sortKey: 'lastAccessed',
      sortOrder: 'asc',
      displayNumber: 10,
      pageNumber: 0,
    })

  /** 表示対象のカスタマーデータ */
  const adjustAccountTableRow = useMemo(() => {
    if (!isUndefined(displayCondition)) {
      let newAccountArray = displayCondition.searchValue
        ? props.customers.filter((customer) =>
            customer.customerName.includes(displayCondition.searchValue)
          )
        : props.customers
      /** ソートキー、ソートオーダーによる並び替え */
      if (displayCondition.sortKey === 'lastAccessed') {
        newAccountArray = newAccountArray.sort((item, item2) => {
          return (
            ((item2.lastAccessed ? item2.lastAccessed : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.lastAccessed ? item.lastAccessed : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (displayCondition.sortOrder === 'asc' ? -1 : 1)
          )
        })
      }
      /** 表示するカスタマーの整形 */
      return newAccountArray.slice(
        displayCondition.displayNumber * displayCondition.pageNumber,
        (displayCondition.pageNumber + 1) * displayCondition.displayNumber
      )
    }
    return props.customers
  }, [displayCondition, props.customers])

  /** テーブルに表示するカスタマーのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: Customer[] = adjustAccountTableRow.map(
      (customerList: Customer) => {
        return {
          customerName: customerList.customerName,
          customerId: customerList.customerId,
          role: customerList.role,
          lastAccessed: customerList.lastAccessed,
        }
      }
    )

    return convertedList.map((data, index) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'customerName':
            return value ? (
              <Link
                variant='body1'
                className={classes.link}
                underline='none'
                onClick={() => {
                  props.changeCustomer(convertedList[index].customerId)
                  const newUrl = getChangeUrl(
                    history.location.pathname,
                    convertedList[index].customerId
                  )
                  history.push(newUrl)
                  props.handleCloseDialog()
                }}
              >
                {value}
              </Link>
            ) : (
              <Box sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'role':
            return <Typography>{getCustomerRoleName(value)}</Typography>
          case 'lastAccessed':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: 'text.secondary' }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          default:
            return value ? (
              <Typography>{value}</Typography>
            ) : (
              <Box sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [adjustAccountTableRow])

  /** 検索文字列の変更 */
  const handleChangeSearchValue = (searchValue: string) => {
    setSearchValue(searchValue)
  }

  /** 検索文字列での検索実行 */
  const searchFromSearchValue = () => {
    setDisplayCondition({
      ...displayCondition,
      searchValue: searchValue,
      pageNumber: 0,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const maxPageNumber = Math.ceil(props.customers.length / displayNumber) - 1
    if (displayCondition.pageNumber <= maxPageNumber) {
      /** 表示数を変更 */
      setDisplayCondition({
        ...displayCondition,
        displayNumber: displayNumber ?? 10,
      })
    } else {
      /** 表示数を変更 */
      setDisplayCondition({
        ...displayCondition,
        pageNumber: maxPageNumber,
        displayNumber: displayNumber ?? 10,
      })
    }
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    setDisplayCondition({
      ...displayCondition,
      sortKey: key,
      sortOrder: displayCondition.sortOrder === 'desc' ? 'asc' : 'desc',
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    setDisplayCondition({
      ...displayCondition,
      pageNumber: pageNumber,
    })
  }

  return {
    changeTableSortOrder,
    pageChange,
    searchValue,
    handleChangeSearchValue,
    handleChangeDisplayNumber,
    searchFromSearchValue,
    tableRows,
    displayCondition,
  }
}

/**
 * カスタマー切り替え後の新しいURLのパスを取得する
 * @param pathname 現在のURLのパス(history.location.pathname)
 * @param customerId 切り替え先のカスタマーID
 * @returns 切り替え先のURLのパス
 */
const getChangeUrl = (pathname: string, customerId: string) => {
  const changedUrl = pathname.split('/')
  changedUrl[1] = customerId
  return changedUrl.join('/')
}

const getCustomerRoleName = (role: string) => {
  switch (role) {
    case 'admin':
      return '管理者'
    case 'developer':
      return 'AIエンジニア'
    default:
      return 'ユーザー'
  }
}

export const CustomerChangeDialogPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerChangeDialog))
