import { Dispatch } from 'redux'
import { State } from 'state/store'
import { classSetListActions } from './actions'
import {
  getClassSetCollection,
  getClassSetMetaDataCollection,
} from 'state/firebase'
import { ClassSetListDisplayCondition, ClassSet } from './types'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { isUndefined } from 'utils/typeguard'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForClassSetsDocument } from 'utils/fireStore/classSet'
import { domainDataOperations } from 'state/app/domainData/operations'

const createClassSetListData = async (
  userGroupId: string,
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup',
  snapShot: QuerySnapshot<DocumentData>
): Promise<(ClassSet | undefined)[]> =>
  // 関連のDocsを取得し表示用のクラスセット一覧を生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const classSetsDocData = document.data()
      if (!fireStoreTypeGuardForClassSetsDocument(classSetsDocData)) {
        return undefined
      }

      // class-set-metadata document を取得
      const classSetMetadataQuery =
        selectedUserGroupKind === 'UserGroup'
          ? query(
              getClassSetMetaDataCollection(userGroupId),
              where('class-set-id', '==', classSetsDocData['class-set-id'])
            )
          : query(
              getClassSetMetaDataCollection(userGroupId),
              where('class-set-id', '==', classSetsDocData['class-set-id']),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )

      const classSetMetadataDocs = (await getDocs(classSetMetadataQuery)).docs
      const classSetMetadataData = classSetMetadataDocs.at(0)?.data()

      return {
        classSetId: classSetsDocData['class-set-id'],
        classSet: classSetsDocData['class-list'].join(', '),
        classSetName: classSetMetadataData?.['name'] ?? '',
        remarks: classSetMetadataData?.['remarks'],
        createdAt: classSetsDocData['created-at'],
        userGroupId: classSetsDocData['user-group-id'],
      } as ClassSet
    })
  )

export const onUpdateClassSetsDocument = async (
  dispatch: Dispatch,
  userGroupId: string,
  classSetsSnapshot: QuerySnapshot<DocumentData>,
  classSetIds: string[],
  condition: ClassSetListDisplayCondition,
  classSetsDoc: DocumentData | undefined
) => {
  const classSetsData = await createClassSetListData(
    userGroupId,
    condition.selectedUserGroupKind,
    classSetsSnapshot
  )
  if (classSetsData.length >= 0) {
    if (classSetsDoc) {
      const index = classSetIds.findIndex((id) => id === classSetsDoc?.id)
      const beforePageIds = classSetIds.slice(0, index + 1)
      dispatch(
        classSetListActions.setClassSetIdList([
          ...beforePageIds,
          ...classSetsSnapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        classSetListActions.setClassSetIdList([
          ...classSetIds,
          ...classSetsSnapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }

  // 取得したクラスセットの一覧を保持
  dispatch(
    classSetListActions.setList(
      classSetsData.filter((item) => item !== undefined) as ClassSet[]
    )
  )

  let totalCountQuery =
    condition.selectedUserGroupKind === 'UserGroup'
      ? query(getClassSetCollection(userGroupId))
      : query(
          getClassSetCollection(userGroupId),
          where('access-control.is-shared', '==', true),
          where('access-control.share-permissions.webapp', '==', 'list')
        )
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      where('class-set-id', '>=', startCode),
      where('class-set-id', '<=', endCode)
    )
  }
  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    classSetListActions.setClassSetListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}

// クラスセット一覧の配列をセット
const getClassSetDocs = async (dispatch: Dispatch, getState: () => State) => {
  const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
    dispatch,
    getState
  )

  // 共有データの参照権がない場合は、カスタマーデータに変更する
  if (!hasSharedUserGroup) {
    dispatch(
      classSetListActions.setClassSetListDisplayCondition({
        ...getState().pages.classSetListState.domainData
          .classSetListDisplayCondition,
        selectedUserGroupKind: 'UserGroup',
      })
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.classSetListState.domainData.classSetListDisplayCondition

  const userGroupId =
    condition.selectedUserGroupKind === 'UserGroup'
      ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
      : getState().app.domainData.authedUser.auth.customClaims.sharedList[0]

  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.classSetListState.domainData.currentClassSetListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(classSetListActions.setCurrentClassSetListSnapshot(undefined))
  }

  // ベースのQuery（表示件数分指定）
  let commonQuery =
    condition.selectedUserGroupKind === 'UserGroup'
      ? query(
          getClassSetCollection(userGroupId),
          limit(condition.displayNumber)
        )
      : query(
          getClassSetCollection(userGroupId),
          where('access-control.is-shared', '==', true),
          where('access-control.share-permissions.webapp', '==', 'list')
        )

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('class-set-id', 'asc'),
      where('class-set-id', '>=', startCode),
      where('class-set-id', '<=', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const classSetIds = getState().pages.classSetListState.domainData.classSetIds
  // 取得済みのクラスセットの最後尾
  let classSetsDoc: DocumentData | undefined = undefined
  if (classSetIds.length > 0) {
    classSetsDoc = await getDoc(
      doc(
        getClassSetCollection(userGroupId),
        classSetIds[classSetIds.length - 1]
      )
    )
  }

  // 取得済みのクラスセットが存在する場合は、取得済みの最後尾以降のデータから取得する
  if (classSetsDoc) {
    commonQuery = query(commonQuery, startAfter(classSetsDoc))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateClassSetsDocument(
        dispatch,
        userGroupId,
        snapshot,
        classSetIds,
        condition,
        classSetsDoc
      )
    }
  )

  // 現在、表示中のクラスセット（ML Pipeline Docsの Snapshot を保持）
  dispatch(classSetListActions.setCurrentClassSetListSnapshot(snapshot))
}

export const classSetListOperations = {
  /** リストを取得する */
  getClassSetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(classSetListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.classSetListState.domainData.classSetIds
        const condition =
          getState().pages.classSetListState.domainData
            .classSetListDisplayCondition
        const classSetIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(classSetListActions.setClassSetIdList(classSetIds))

        await getClassSetDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(classSetListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.classSetListState.domainData
            .currentClassSetListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
